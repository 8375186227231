<template>
  <div>
    <div class="col">
          <!-- <h4 class="mt-0 header-title">TRIAL GROUP</h4> -->
        </div>
    <!-- <div class="col-auto">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('list') }]"
                @click.prevent="setActiveTab('list'),fetchTrialGroupList(),clearFields()"
              >
                List
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('create') }]"
                @click.prevent="setActiveTab('create')"
              >
                Create
              </a>
            </li>
          </ul>
        </div> -->
        <div>
          <div class="mt-3">

            <md-card>
              <md-card-header data-background-color="green">
                <h4 class="title">ALLOCATION GROUPWISE TARE</h4>
              </md-card-header>

              <md-card-content>
                <div class="row mt-3">
                  <div class="col-md-12 d-flex">
                    <!-- <label class="ml-1">
                      <input
                        type="radio"
                        class="w-auto mr-1"
                        name="options"
                        value="option"
                      />Feeder Drum</label
                    > -->
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-2 d-flex">
                    <label class="my-auto ml-1">Group: </label>
                    <select v-model="selectedTrialGroupId" class="form-control width-10 ml-2 pl-2">
                      <option v-for="(trial,index) in trialGroups" :key="index" :value="trial.trial_group_id">
                        T{{ trial.trial_group_id }}
                      </option>
                      <option :value="trialGrpsLength">All</option>
                    </select>
                  </div>
                  <div class="col-md-4 d-flex">
                    <label for="movie" class="my-auto"> Trial No: </label>
                    <select name="" v-model="trialNo" class="form-control width-10 ml-2 pl-2" id="">
                      <option v-for="trial in trialNumbers" :key="trial.id" :value="trial.id">
                        {{ trial.trial_no }}
                      </option>
                    </select>
                  </div>
                </div>
                <loading-bar v-if="load"></loading-bar>
                <div v-else>
                <div v-if="!allData" class="table-container mt-5">
                  <div class="custm-header">
                    <div class="t-cell">Pen No</div>
                    <div class="t-cell">PST</div>
                    <div class="t-cell">ST</div>
                    <div class="t-cell">FN1</div>
                    <div class="t-cell">FN2</div>
                  </div>
                  
                    <div v-if="pens.length">
                        <div
                          v-for="(item, index) in pens"
                          :key="index"
                          class="custm-row">
                            <div class="t-cell" id="cll">
                              <span>{{ item.pen_name }}</span>
                            </div>
                            <div class="t-cell">
                              <input type="number" v-model="item.pst" readonly/>
                            </div>
                            <div class="t-cell">
                              <input type="number" v-model="item.st" readonly/>
                            </div>
                            <div class="t-cell">
                              <input type="number" v-model="item.fn1" readonly/>
                            </div>
                            <div class="t-cell">
                              <input type="number" v-model="item.fn2" readonly/>
                            </div>
                        </div>
                        <div class="custm-row average-row">
                          <div class="t-cell" id="cll">Average</div>
                          <div class="t-cell"><b><input type="number" :value="averages.pst" readonly style="font-weight: bold;"/></b></div>
                          <div class="t-cell"><b><input type="number" :value="averages.st" readonly style="font-weight: bold;"/></b></div>
                          <div class="t-cell"><input type="number" :value="averages.fn1" readonly style="font-weight: bold;"/></div>
                          <div class="t-cell"><input type="number" :value="averages.fn2" readonly style="font-weight: bold;"/></div>
                        </div>
                    </div>
                </div>

                <div v-else class="table-container mt-5">
                  <div class="custm-header">
                    <div class="t-cell">Pen No</div>
                    <div class="t-cell">PST</div>
                    <div class="t-cell">ST</div>
                    <div class="t-cell">FN1</div>
                    <div class="t-cell">FN2</div>
                  </div>
                  <loading-bar v-if="load"></loading-bar>
                    <div>
                        <div
                          v-for="(item, index) in temp"
                          :key="index"
                          class="custm-row">
                          <div class="t-cell" id="cll">
                            {{ item.pen_name }}
                          </div>
                          <div class="t-cell">
                            <input type="number" v-model="item.pst" readonly/>
                          </div>
                          <div class="t-cell">
                            <input type="number" v-model="item.st" readonly/>
                          </div>
                          <div class="t-cell">
                            <input type="number" v-model="item.fn1" readonly/>
                          </div>
                          <div class="t-cell">
                            <input type="number" v-model="item.fn2" readonly/>
                          </div>
                        </div>
                        <div class="custm-row average-row">
                          <div class="t-cell" id="cll">Average</div>
                          <div class="t-cell"><input type="number" :value="averages2.pst" readonly style="font-weight: bold;"/></div>
                          <div class="t-cell"><input type="number" :value="averages2.st" readonly style="font-weight: bold;"/></div>
                          <div class="t-cell"><input type="number" :value="averages2.fn1" readonly style="font-weight: bold;"/></div>
                          <div class="t-cell"><input type="number" :value="averages2.fn2" readonly style="font-weight: bold;"/></div>
                        </div>
                    </div>
                </div>
              </div>
                <!-- <div class="table-container mt-5">
                  <div class="custm-header">
                    <div class="t-cell">Average</div>
                  </div> -->
              </md-card-content>

              <!-- <md-card>
                <md-card-content>
                </md-card-content>
              </md-card> -->
            </md-card>
          </div>
        </div>

        <!-- <div v-if="activeTab === 'create'">
          <md-card>
            <md-card-content>
              <div v-if="!allData" class="table-container mt-5">
                        <div class="custm-header">
                          <div class="t-cell">Pen No</div>
                          <div class="t-cell">PST</div>
                          <div class="t-cell">ST</div>
                          <div class="t-cell">FN1</div>
                          <div class="t-cell">FN2</div>
                        </div>
                      <loading-bar v-if="load"></loading-bar>
                        <div v-if="feederDrumsData.feederDrumList.length">
                            <div
                              v-for="(item, index) in feederDrumsData.feederDrumList"
                              :key="index"
                              class="custm-row">
                                  <div class="t-cell">
                                    <select
                                      class="select-cell"
                                      v-model="allotedPenList[index]"
                                      @change="updateFilteredPenList"
                                    >
                                      <option
                                        v-for="pen in filteredPenList(index)"
                                        :key="pen"
                                        :value="pen"
                                      >
                                        P{{ pen }}
                                      </option>
                                    </select>
                                  </div>
                                  <div class="t-cell">
                                    <input type="number" v-model="item.pst" readonly/>
                                  </div>
                                  <div class="t-cell">
                                    <input type="number" v-model="item.st" readonly/>
                                  </div>
                                  <div class="t-cell">
                                    <input type="number" v-model="item.fn1" readonly/>
                                  </div>
                                  <div class="t-cell">
                                    <input type="number" v-model="item.fn2" readonly/>
                                  </div>
                            </div>
                            <div class="custm-row average-row">
                              <div class="t-cell">Average</div>
                              <div class="t-cell"><input type="number" :value="averages.pst" readonly/></div>
                              <div class="t-cell"><input type="number" :value="averages.st" readonly/></div>
                              <div class="t-cell"><input type="number" :value="averages.fn1" readonly/></div>
                              <div class="t-cell"><input type="number" :value="averages.fn2" readonly/></div>
                            </div>
                        </div>
                  </div>
              </md-card-content>
            <md-button @click="save()" class="md-success" >{{'Save'}}</md-button>
          </md-card>
        </div> -->
  </div>
</template>

<script>
import LoadingBar from "../components/LoadingBar.vue";
export default {
  components: {LoadingBar},
  name: "simple-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "list", // Default active tab
      trialNumbers:[],
      trialGroups:[],
      mergedData:[],
      selectedTrialGroupId: '',
      pens:[],
      selectedGroup:[],
      trialNo:'',
      trialGroupPenlist:[],
      groupWiseAllocData: [
        { penNo: "", pst: "", st: "", fn1: "", fn2: "", isEdit: false },
      ],
      savedData: [],
      load: true,
      allData: false,
      temp:[],
      trialGrpsLength:null,
      // penList:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
      // allotedPenList:[]
    };
  },
  mounted(){
    this.getTrialNo();
    this.getFeederDrumTareWeight();
  },
  watch:{
    // filteredPenList(val){
    //   // this.penLists.filter((i)=>i.)
    //   return this.penList=this.penList.filter((i)=>i!=val);

    // },

    // temp() {
    //   console.log("yuu")
    // },

    trialNumbers: {
    immediate: true,
      handler(newList) {
        if (newList.length > 0) {
          this.trialNo = newList[0].id;
          this.trialGrpsLength = newList.length;
        }
      }
    },

    trialNo(newVal, oldVal) {
      if (newVal) {
        this.getInfo(newVal);
      }
    },

    selectedTrialGroupId(newVal){
      // console.log(newVal);
      if(newVal==this.trialGrpsLength){

      this.allData=true;
      this.selectedGroup = this.trialGroups.map(group => {
          return {
              trial_group_id: group.trial_group_id,
              pens: group.pens.map(pen => {
                    const drum = this.feederDrumsData.feederDrumList.find(d => d.id === pen.pen_id);
                    return drum ? { ...pen, ...drum } : pen;
                  })
              };
        });
      // console.log(this.selectedGroup);
      this.pens = this.selectedGroup ? this.selectedGroup.pens : [];
      }
      else {
        this.allData=false;
        // this.selectedTrialGroupId = this.trialGroups[0].trial_group_id;
        if(this.selectedTrialGroupId){
          this.selectedGroup = this.mergedData.find(group => group.trial_group_id === this.selectedTrialGroupId);
          this.pens = this.selectedGroup ? this.selectedGroup.pens : [];
          // console.log("ii",this.selectedGroup);
          // console.log("ll",this.pens);
        }
      }
    },
  },

  computed: {
    // filteredPenList() {
    //   return (index) => {
    //     // Get the selected pens excluding the current row
    //     const selectedPens = this.allotedPenList.filter((_, i) => i !== index);
    //     // Return penList excluding selected pens
    //     return this.penList.filter(pen => !selectedPens.includes(pen));
    //   };
    // },

    averages() {
      const total = { pst: 0, st: 0, fn1: 0, fn2: 0 };
      const count = 5;

      this.pens.forEach(pen => {
        total.pst += parseFloat(pen.pst) || 0;
        total.st += parseFloat(pen.st) || 0;
        total.fn1 += parseFloat(pen.fn1) || 0;
        total.fn2 += parseFloat(pen.fn2) || 0;
      });
      
      return {
        pst: (total.pst / count).toFixed(3),
        st: (total.st / count).toFixed(3),
        fn1: (total.fn1 / count).toFixed(3),
        fn2: (total.fn2 / count).toFixed(3)
      };
    },
    averages2() {
      const total = { pst: 0, st: 0, fn1: 0, fn2: 0 };
      const count = this.temp.length;

      this.temp.forEach(pen => {
        total.pst += parseFloat(pen.pst) || 0;
        total.st += parseFloat(pen.st) || 0;
        total.fn1 += parseFloat(pen.fn1) || 0;
        total.fn2 += parseFloat(pen.fn2) || 0;
      });
      
      return {
        pst: (total.pst / count).toFixed(3),
        st: (total.st / count).toFixed(3),
        fn1: (total.fn1 / count).toFixed(3),
        fn2: (total.fn2 / count).toFixed(3)
      };
    }
  },


  methods: {
    // save() {
    // const savedData = this.feederDrumsData.feederDrumList.map((item, index) => {
    //         return {
    //             pen_id: this.allotedPenList[index],
    //             feeder_drum_id: item.id,
    //         };
    //     });
    //     this.penList=[];

    //     if (savedData.length >= 25) {
    //         axios.post('/indous-research/groupwise-tare-allocation', {
    //             experiment_info_id: this.trialNo,
    //             data: savedData
    //         }).then(response => {
    //             console.log('Data saved successfully:', response.data);
    //         }).catch(error => {
    //             console.error('Error saving data:', error);
    //         });
    //     }
    // },

    // updateFilteredPenList() {
    //   // Trigger reactivity
    //   console.log(this.allotedPenList);
    //   this.allotedPenList = [...this.allotedPenList];
    // },

    isActive(tab) {
      return this.activeTab === tab;
    },
    
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    mergePensWeight(){
    this.mergedData = this.trialGroups.map(group => {
          return {
              trial_group_id: group.trial_group_id,
              pens: group.pens.map(pen => {
                    const drum = this.feederDrumsData.feederDrumList.find(d => d.id === pen.pen_id);
                    return drum ? { ...pen, ...drum } : pen;
                  })
              };
        });

        this.temp=this.mergedData.flatMap(group => group.pens).sort((a, b) => a.pen_id - b.pen_id);
        // console.log("feederd",this.feederDrumsData.feederDrumList);
    },

    getTrialNo(){
      axios.get('/indous-research/experiment-information/get-trial-no-list').then(response=>{
        this.trialNumbers = response.data.trialNoList;
        // this.trailNo=this.trialNumbers[this.trialNumbers.length-1].trial_no;
      })
    },

    getInfo(newVal){
      this.load=true;
      axios.get(`/indous-research/get-alloted-pen-lists/${newVal}`)
      .then(response => {
          this.trialGroups = response.data.result;

          let uniqueTrialGroups = [];

          this.trialGroups.forEach(group => {
              if (!uniqueTrialGroups.some(uniqueGroup => uniqueGroup.trial_group_id === group.trial_group_id)) {
                  uniqueTrialGroups.push(group);
              }
          });

          this.trialGroups = uniqueTrialGroups;

          // console.log("cjj",this.trialGroups);
          // console.log("oo",this.trialGroups);
          if(this.trialGroups.length){
            this.mergePensWeight();
            this.selectedTrialGroupId = this.trialGroups[0].trial_group_id;

            // console.log("kkk",this.trialGroups[0].trial_group_id);
            // console.log("ooo",this.selectedTrialGroupId);
            // this.selectedTrialGroupId = this.trialGroups[0].trial_group_id;
            this.load=false;
            this.selectedGroup = this.mergedData.find(group => group.trial_group_id === this.selectedTrialGroupId);
          this.pens = this.selectedGroup ? this.selectedGroup.pens : [];
          // console.log("ii",this.selectedGroup);
          // console.log("ll",this.pens);
          }else {
            this.selectedTrialGroupId = null;
            this.pens=[];
            this.load=false;
          }
        })
        .catch(error => {
          // console.error('Error fetching trial group and pen list:', error);
        });
    },


    


    getFeederDrumTareWeight(){
      // Fetch data from the backend
      axios.get('/indous-research/mst-feeder-drums/get-feeder-drums')
      .then(response => {
          this.feederDrumsData = response.data;
          // console.log("g",this.feederDrumsData);
        })
        .catch(error => {
          // console.error("There was an error fetching the mst_feeder_drums data!", error);
        });
    },

    getAllocatedFeederDrumTareWeight(){
      // Fetch data from the backend
      axios.get('/indous-research/mst-feeder-drums/get-feeder-drums')
      .then(response => {
          this.feederDrumsData = response.data;
          // console.log("g",this.feederDrumsData);
        })
        .catch(error => {
          // console.error("There was an error fetching the mst_feeder_drums data!", error);
        });
    },

    addRowGroupWiseAlloc() {
      this.groupWiseAllocData.push({
        penNo: "",
        pst: "",
        st: "",
        fn1: "",
        fn2: "",
        isEdit: false,
      });
    },

    saveChanges(item) {
      item.isEdit = false;
      // Additional logic to save changes if needed
    },

    // saveData() {
    //   this.savedData.push(...this.groupWiseAllocData);
    //   this.groupWiseAllocData = [
    //     { penNo: "", pst: "", st: "", fn1: "", fn2: "" },
    //   ];
    // },

    toggleEdit(item) {
      item.isEdit = !item.isEdit;
    },
  },
};
</script>

<style scoped>
.select-cell{
    word-wrap: normal;
    width: 100%;
    height: 100%;
}
.custm-row {
  display: flex;
}
.t-cell {
  flex: 1;
}
.average-row {
  font-weight: bold;
}
.custom-md-field {
  width: 190px;
}

.custom-md-input {
  width: 50px;
  font-size: 14px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-group {
  display: inline;
}

button[type="submit"],
button[type="button"].cancel-button {
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}

.slct2 {
  height: 40px;
  width: 130px;
}
td {
  font-weight: normal;
}
.select-style {
  background-color: transparent;
}

#cll {
  padding-top:0.7%;
}
</style>