import axios from 'axios';
import store from '../store/store'
import {routerExport} from '../main.js'
function getToken() {
  return store.getters.getToken; // Adjust according to your storage method
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_PATH_API, // Update with your Laravel API URL
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  error => Promise.reject(error)
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  const isAuthenticated = store.getters.isAuthenticated;
  if (error.response && error.response.status === 401 && isAuthenticated ) {
    // console.log('expired',error.response.data.message);
      // Clear token and redirect to login page
      store.commit('clearAuth'); // Clear the token from localStorage
      // store.commit('setUser',null);
      alert('Session timed out')
      routerExport.push('/login'); // Redirect to the login page, assuming you have a 'login' named route
  }
  return Promise.reject(error);
});
export default axiosInstance;