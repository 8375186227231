import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
    state() {
        return {
            token:null,
            user:null,
            subMenus:null,
            activeSideBar:null

        };
    },
    mutations: {
        setToken: (state, token) => {
            state.token = token;
        },
        setUser:(state,user)=>
        {
            state.user = user
        },
        setSubMenus:(state, submenus) =>
        {
           state.subMenus = submenus;
        },
        setActiveSideBar: (state,sidebar)=> 
        {
           state.activeSideBar = sidebar
        },
        clearAuth:(state)=>
        {
           state.user = null;
           state.token = null;
        }
    
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        getUser(state) {
            return state.user;
        },
        isAuthenticated(state) {
            return !!state.token;
        },
        getSubMenus(state) {
            return state.subMenus;
        },
        getActiveSideBar(state) {
            return state.activeSideBar;
        },

    },
    plugins: [createPersistedState()],
});

export default store;
