<template>
  <div>
    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">

        <div class="col-md-4 d-flex">
          <label for="movie" class="mt-2 font-weight-bold"> Trial No: </label>
          <select
            class="form-control width-10 ml-2 pl-2"
            v-model="trialNoDrp"
            @change="filterStartDate(), mortalBirdsReportList=[], reportGenerated = false "
          
          >
            <option v-for="item in trialNoList" :key="item.id" :value="item.id">
              {{ item.trial_no }}
            </option>
          </select>
        </div>

        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold">
            Start date
          </label>
          <input
            type="text"
            v-model="startDate"
            disabled
            class="form-control custom-input-width ml-2 pl-2"
          />
        </div>

        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold"> End date </label>
          <input
            type="text"
            v-model="endDate"
            disabled
            class="form-control custom-input-width ml-2 pl-2"
          />
        </div>
      </div>

      <div class="row align-items-center mb-5">
        <div class="col-md-4"></div>
        <div class="col-md-2">
          <!-- <md-button class="greyblue-button">Generate Report</md-button> -->
          <button type="button" class="btn btn-sm btn-info" @click="fetchMortalBirdsReportList()">
            <md-icon class="text-white">list</md-icon> Generate Report
          </button>
        </div>

        <div class="col-md-2">
          <!-- <md-button class="bg-info">
              <md-icon>download</md-icon> Export Data
            </md-button> -->
          <button type="button" 
           class="btn btn-sm btn-primary" 
          :disabled="!reportGenerated"
          @click="exportData()"
          >
            <md-icon class="text-white">download</md-icon>Export Data
          </button>
        </div>

        <div class="col-md-2">
          <button type="button" 
          class="btn btn-sm btn-success"
          :disabled="!reportGenerated"
          @click="downloadPDF"
          >
            <md-icon class="text-white">download</md-icon> Download PDF
          </button>
        </div>
        <!-- <div class="col-md-2 d-flex">
          <md-field class="custom-md-field">
            <label>Search...</label>
            <md-input v-model="initial"></md-input>
          </md-field>
          <md-icon>search</md-icon>
        </div> -->
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">MORTAL BIRDS DETAILS</h4>
            <!-- <p class="category">List of Users</p> -->
          </md-card-header>
          <md-card-content>
            <md-table
              v-model="users"
              :table-header-color="tableHeaderColor"
              class="custom-table"
              id="mortalBirdsTable"
            >
              <tr>
                <th>Sl No</th>
                <th>Date</th>
                <th>Group</th>
                <th>Pen No</th>
                <th>Mortality</th>
                <th>Mortal Birds Wt</th>
                <th>Total</th>
                <th>Cumulative</th>
              </tr>

              <tr v-if="!mortalBirdsReportList.length && !load">
                <td colspan="16" class="text-center">
                  {{
                    reportGenerated
                      ? "No records found"
                      : "Please Generate report"
                  }}
                </td>
              </tr>

              <tr v-for="(item,index) in mortalBirdsReportList" :key="item.index">
                <!--item-grp?-->
                <td>{{ index+1 }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.trial_groups.trial_group_id }}</td>
                <td>{{ item.pen_name }}</td>
                <td>{{ item.mortality }}</td>
                <td>{{ item.mortality_bird_wt }}</td>
                <td>{{ item.mortality }}</td>
                  <!-- //item.total -->
                <td>{{ item.cumulative_mortality }}</td>
                <!-- //item.cumulative -->
              </tr>
            </md-table>
            <loading-bar v-if="load"></loading-bar>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
  
  <script>
import LoadingBar from "../../components/LoadingBar.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

export default {
  components: { LoadingBar },
  name: "MortalDetails",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeTab: "list", // Default active tab

      trialNoList: [],
      expInfoId: "",
      trialNoDrp: "",
      startDate: "",

      endDate: "",

      mortalBirdsReportList: [],

      load: false,
      reportGenerated: false,

    };
  },

  mounted() {
    this.getTrialNoList();
  },

  // watch: {
  //   trialNoDrp: function (value) {
  //     this.getTrialNoList();
  //   },
  // },

  methods: {
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    getTrialNoList() {
      // if (!this.startDate) return;

      axios
        .get(`/indous-research/reports/get-trial-list`)
        .then((response) => {
          this.trialNoList = response.data.trialNoList;
          this.trialNo = this.trialNoList[0]?.trial_no;
          this.trialNoDrp = this.trialNoList[0]?.id;
          this.expInfoId = this.trialNoList[0]?.id;
          this.startDate = this.trialNoList[0]?.start_date;
          this.endDate = this.trialNoList[0]?.end_date;
          // this.fetchGroupwiseWeightReportList();
        });
    },

    filterStartDate() {
      let trialData = this.trialNoList.filter((i) => i.id == this.trialNoDrp);
      this.startDate = trialData[0].start_date;
      this.endDate = trialData[0].end_date;
    },

    
    fetchMortalBirdsReportList() {
      this.load = true;
      this.mortalBirdsReportList = [];
      axios
        .get(
          `/indous-research/reports/Mortal-birds-details?exp_info_id=${this.trialNoDrp}`
        )
        .then((response) => {
          this.mortalBirdsReportList = response.data.mortalBirdsReportList;
          this.reportGenerated = true; // Enable buttons after report generation
          this.load = false;
        })
        .catch(() => {
          this.load = false;
        });
    },



//    exportData() {
//       const table = document.getElementById("mortalBirdsTable");
//       let ws = XLSX.utils.table_to_sheet(table);

//       // Convert sheet to JSON to shift the content
//       const tableContent = XLSX.utils.sheet_to_json(ws, { header: 1, raw:false });

//       const mainHeading = ['MORTAL BIRDS DETAILS'];
//       const formattedData = [mainHeading, ...this.formatColumnHeadings(tableContent)];
  
//       const worksheet = XLSX.utils.aoa_to_sheet(formattedData);
      
//       // Merge cells for the main heading
//       worksheet['!merges'] = [
//           { s: { r: 0, c: 0 }, e: { r: 0, c: formattedData[1].length - 1 } } // Adjust the range for merging
//       ];
      
//       // Center the text in the merged cell
//       worksheet['A1'].s = {
//           alignment: { vertical: 'center', horizontal: 'center' }
//       };
  
//       const columnWidths = this.calculateColumnWidths(formattedData.slice(1)); // Calculate widths without the main heading row
//       worksheet['!cols'] = columnWidths;
  
//       const workbook = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

//       XLSX.writeFile(workbook, 'MortalBirdsReport.xlsx');
// },




exportData() {
      axios
        .post(
          "/download-mortal-birds-excel-report",
          {
            reportData: this.mortalBirdsReportList,
          },
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "MortalBirdsReport.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          // console.error("There was an error generating the report:", error);
        });
    },




formatColumnHeadings(data) {
    const headers = data[0].map(header => header); // Format headers
    const formattedData = [headers, ...data.slice(1)]; // Combine formatted headers with data
    return formattedData;
},

 
calculateColumnWidths(data) {
    const columnWidths = data[0].map((header, colIndex) => {
        let maxLength = header.length;
        data.slice(1).forEach(row => {
            const cellValue = row[colIndex] ? row[colIndex].toString() : '';
            maxLength = Math.max(maxLength, cellValue.length);
        });
        return { wch: maxLength };
    });
 
    return columnWidths;
},



    downloadPDF() {
    const doc = new jsPDF('landscape'); // Landscape orientation
    const tableColumn = [
      "Sl No", 
      "Date", 
      "Group", 
      "Pen No", 
      "Mortality", 
      "Mortal Birds Wt", 
      "Total",
      "Cumulative"
    ];
    const tableRows = [];

    this.mortalBirdsReportList.forEach((item,index) => {
      const tableData = [
        index+1,
        item.date,
        item.trial_groups.trial_group_id,
        item.pen_name,
        item.mortality,
        item.mortality_bird_wt,
        item.mortality,
        item.cumulative_mortality
      ];
      tableRows.push(tableData);
    });

    // Add title to PDF
    doc.setFontSize(14);
    doc.text("MORTAL BIRDS DETAILS", 14, 22);

    // Add table to PDF
    doc.autoTable(tableColumn, tableRows, {
      startY: 30,
      theme: 'striped', 
      headStyles: { fillColor: [41, 128, 185] }, // Header background color
      styles: { cellPadding: 3 },
      columnStyles: {
        0: { cellWidth: 'auto' }, 
        1: { cellWidth: 'auto' }, 
        2: { cellWidth: 'auto' }, 
        3: { cellWidth: 'auto' }, 
        4: { cellWidth: 'auto' }, 
        5: { cellWidth: 'auto' }, 
        6: { cellWidth: 'auto' },
        7: { cellWidth: 'auto' }
      },
    });

    // Save PDF
    doc.save("MortalBirdsReport.pdf");
  },

  },
};
</script>
  
  <style scoped>
.custom-md-field {
  width: 190px;
}

.custom-md-input {
  width: 50px;
  font-size: 14px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-group {
  display: inline;
}

button[type="submit"],
button[type="button"].cancel-button {
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}
</style>