<template>
    <div>
      <div class="form-container">
        <div class="form-groupx">
          <label for="trial-no">Trial No</label>
          <select id="trial-no" class="readonly-select" v-model="selectedTrial" @change="fetchTrialDetails()" disabled>
            <option v-for="trial in trials" :key="trial.id" :value="trial.id">
              {{ trial.trial_no }}
            </option>
          </select>
        </div>
        <div class="form-groupx">
          <label for="start-date">Trial Start Date</label>
          <input type="text" id="start-date" :value="startDate" readonly />
        </div>
        <div class="form-groupx">
          <label for="date">Date</label>
          <input type="text" id="date" v-model="currentDate" readonly/>
        </div>
      </div>

      <md-card>
        <md-card-content>
          <div class="header-sec"></div>
          <md-card-header data-background-color="green" style="margin: 0px 0px 1%">
            <h4 class="title">Feed Consumption Per Bird (in KGS)</h4>
          </md-card-header>
          <div>
            <div class="row align-items-center mb-3">
                <div class="col-auto">
                    <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a
                        href="#"
                        :class="['nav-link', { active: isActive('view') }]"
                        @click.prevent="setActiveTab('view')"
                        >
                        View
                        </a>
                    </li>
                    <li class="nav-item" v-if=" permissionData.add && permissionData.edit">
                        <a
                        href="#"
                        :class="['nav-link', { active: isActive('create') }]"
                        @click.prevent="setActiveTab('create')"
                        >
                        Create / Edit
                        </a>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            <form v-if="activeTab === 'view'">
                <div class="row align-items-center mb-1 justify-content-around">
                    <!-- <div class="col-auto"> -->
                        <label class="font-weight-bold" for="">Select Day: 
                            <select style="width: 100px; height: 40px" v-model="dayWeekView" @change="fetchViewFeedData()">
                                <option value="" disabled>Select Day</option>
                                <option v-for="day in availableDays" :value="day.value" :key="day.value">{{ day.label }}</option>
                            </select>
                        </label>
                        <!-- <label class="font-weight-bold" for="">Trial Group
                            <select v-model="selectedTrialGroup" style="width: 100px; height: 40px">
                                <option v-for="(item, trialIndex) in trialGroupList" :value="item.id" :key="trialIndex">{{ item.trial_group_id }}</option>
                            </select>
                        </label> -->
                    <!-- </div> -->
                </div>
                <md-table id="myTable" v-if="loadingView || !isFeedDataFetchedView || !feedDataView.length > 0">
                    <thead>
                        <tr>
                            <!-- <th style="width: 100px">Age in Days/Weeks</th> -->
                            <!-- <th>Feed Category</th> -->
                            <!-- <th v-if="selectedChickCategory == 3">Formula</th> -->
                            <!-- <th>Trial Group</th> -->
                            <th>Pen No.</th>
                            <th>No of Birds</th>
                            <!-- <th>Feeder</th> -->
                            <th>Feeder with Feed Wt(Kgs)</th>
                            <th>Feeder Tare Wt(Kgs)</th>
                            <th>Remaining Feed in Feeder (Kgs)</th>
                            <th>Feeder Drum with Feed Wt(Kgs)</th>
                            <th>Feeder Drum Tare Wt(Kgs)</th>
                            <th>Remaining Feed in Feeder Drum (Kgs)</th>
                            <th v-if="selectedChickCategoryView == 1 || selectedChickCategoryView == 2 || selectedChickCategoryView == 3">Alloted Feed PST (Kgs)</th>
                            <th v-if="selectedChickCategoryView == 2 || selectedChickCategoryView == 3">Alloted Feed ST (Kgs)</th>
                            <th v-if="selectedChickCategoryView == 3 && (selectedFormulaView == 1 || selectedFormulaView == 2)">Alloted Feed FN1 (Kgs)</th>
                            <th v-if="selectedChickCategoryView == 3 && selectedFormulaView == 2">Alloted Feed FN2 (Kgs)</th>
                            <th>Total Alloted Feed (Kgs)</th>
                            <th>Total Remaining Feed (Kgs)</th>
                            <th>Consumed Feed (Kgs)</th>
                            <th>Feed Consumed Per Bird (Kgs)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="loadingView">
                            <td colspan="18"><loading-bar></loading-bar></td>
                        </tr>
                        <tr v-else>
                            <td colspan="18">No data found</td>
                        </tr>
                    </tbody>
                </md-table>
                <md-table v-else id="myTable" v-for="(penList, indexMain) in feedDataView" :key="indexMain">
                    <!-- <p class="font-weight-bold"></p> -->
                    <thead>
                        <tr >
                            <th colspan="1" style="background-color: #64B968; color: white">{{ 'T' + penList.trial_group_id }}</th>
                        </tr>
                        <tr>
                            <!-- <th style="width: 100px">Age in Days/Weeks</th> -->
                            <!-- <th>Feed Category</th> -->
                            <!-- <th v-if="selectedChickCategory == 3">Formula</th> -->
                            <!-- <th>Trial Group</th> -->
                            <th>Pen No.</th>
                            <th>No of Birds</th>
                            <!-- <th>Feeder</th> -->
                            <th>Feeder with Feed Wt(Kgs)</th>
                            <th>Feeder Tare Wt(Kgs)</th>
                            <th>Remaining Feed in Feeder (Kgs)</th>
                            <th>Feeder Drum with Feed Wt(Kgs)</th>
                            <th>Feeder Drum Tare Wt(Kgs)</th>
                            <th>Remaining Feed in Feeder Drum (Kgs)</th>
                            <th v-if="selectedChickCategoryView == 1 || selectedChickCategoryView == 2 || selectedChickCategoryView == 3">Alloted Feed PST (Kgs)</th>
                            <th v-if="selectedChickCategoryView == 2 || selectedChickCategoryView == 3">Alloted Feed ST (Kgs)</th>
                            <th v-if="selectedChickCategoryView == 3 && (selectedFormulaView == 1 || selectedFormulaView == 2)">Alloted Feed FN1 (Kgs)</th>
                            <th v-if="selectedChickCategoryView == 3 && selectedFormulaView == 2">Alloted Feed FN2 (Kgs)</th>
                            <th>Total Alloted Feed (Kgs)</th>
                            <th>Total Remaining Feed (Kgs)</th>
                            <th>Consumed Feed (Kgs)</th>
                            <th>Feed Consumed Per Bird (Kgs)</th>
                        </tr>
                    </thead>
                    <tbody v-if="loadingView">
                        <tr>
                            <td colspan="18"><loading-bar></loading-bar></td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr  v-for="(pen, index) in penList.data" :key="index">
                            <!-- <template v-if="index === 0">
                                <td rowspan="5">
                                    <select v-model="selectedChickCategory" style="width: 110px; height: 40px" class="font-weight-bold" disabled>
                                        <option v-for="(chick, chickIndex) in chickCategoryList" :value="chick.id" :key="chickIndex">{{ chick.category_name }}</option>
                                    </select>
                                </td>
                                <td rowspan="5" v-if="selectedChickCategory == 3">
                                        <div style="display: flex; align-items: center;">
                                            <label style="margin-right: 10px; font-size: 14px;">
                                                <input type="radio" v-model="selectedFormula" :value="1" style="transform: scale(0.8); margin-right: 4px;" disabled/>
                                                FN 1
                                            </label>
                                            <label style="font-size: 14px;">
                                                <input type="radio" v-model="selectedFormula" :value="2" style="transform: scale(0.8); margin-right: 4px;" disabled>
                                                FN 2
                                            </label>
                                        </div>
                                    </td>
                            </template> -->
                            <td class="font-weight-bold">{{ pen.pen_name }}</td>
                            <td>{{ pen.no_of_birds }}</td>
                            <!-- <td>
                                <select name="" id="" v-model="pen.feeder" class="font-weight-bold" disabled>
                                    <option value="" selected disabled>Select</option>
                                    <option :value="item.id" v-for="(item, feederIndex) in feederMasterData" :key="feederIndex">{{ item.weight_type }}</option>
                                </select>
                            </td> -->
                            <td>{{ pen.feeder_with_feed || '-'}}</td>
                            <td>{{ pen.feeder_tare_wt || '-'}}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.remaining_feed_from_feeder) || '-' }}</td>
                            <td>{{ pen.feeder_drum_with_feed || '-' }}</td>
                            <td>{{ pen.feeder_drum_tare_wt || '-'}}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.remaining_feed_from_feeder_drum) || '-' }}</td>
                            <td class="font-weight-bold" v-if="pen.alloted_feed_pst">{{ formatDecimalPoints(pen.alloted_feed_pst) || '-' }}</td>
                            <td class="font-weight-bold" v-if="pen.alloted_feed_st">{{ formatDecimalPoints(pen.alloted_feed_st) || '-' }}</td>
                            <td class="font-weight-bold" v-if="pen.alloted_feed_fn1">{{ formatDecimalPoints(pen.alloted_feed_fn1) || '-' }}</td>
                            <td class="font-weight-bold" v-if="pen.alloted_feed_fn2">{{ formatDecimalPoints(pen.alloted_feed_fn2) || '-' }}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.total_alloted_feed) || '-' }}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.remaining_feed) || '-' }}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.consumed_feed) || '-'}}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.feed_consumed_per_bird) || '-'}}</td>
                        </tr>
                    </tbody>
                </md-table>
                <!-- <div class="sec-row">
                    <div></div>
                    <div></div>
                    <div v-if="showButtons">
                        <md-button v-if="isFeedDataFetched" @click.prevent="setActiveTab('create')" class="md-success">Edit</md-button>
                    </div>
                </div> -->
            </form>

            <form v-if="activeTab === 'create'">
                <div class="d-flex align-items-center">
                    <label class="font-weight-bold mr-3" for="">Feed Category: 
                        <select v-model="selectedChickCategory" style="width: 110px; height: 40px" @change="feedCalculation()" :disabled="isFeedDataFetched || disableChickCategoryButton || loading">
                            <option value="" selected disabled>Select</option>
                            <option v-for="(chick, chickIndex) in chickCategoryList" :value="chick.id" :key="chickIndex">{{ chick.category_name }}</option>
                        </select>
                    </label>
                        
                    <div v-if="selectedChickCategory == 3" class="d-flex align-items-center">
                        <label class="font-weight-bold" style="font-size: 14px;">FN1 <input type="radio" v-model="selectedFormula" @change="feedCalculation()" :value="1" /></label>
                        <label class="font-weight-bold" style="font-size: 14px;">FN2 <input type="radio" v-model="selectedFormula" @change="feedCalculation()" :value="2" /></label>
                    </div>
                </div>
                <md-table id="myTable">
                    <thead>
                        <tr>
                            <th style="width: 100px">Select Day</th>
                            <!-- <th>Feed Category</th>
                            <th v-if="selectedChickCategory == 3">Formula</th> -->
                            <th>Trial Group</th>
                            <th>Pen No.</th>
                            <th>No of Birds</th>
                            <th>Feeder</th>
                            <th>Feeder with Feed Wt(Kgs)</th>
                            <th>Feeder Tare Wt(Kgs)</th>
                            <th>Remaining Feed in Feeder (Kgs)</th>
                            <th>Feeder Drum with Feed Wt(Kgs)</th>
                            <th>Feeder Drum Tare Wt(Kgs)</th>
                            <th>Remaining Feed in Feeder Drum (Kgs)</th>
                            <th v-if="selectedChickCategory == 1 || selectedChickCategory == 2 || selectedChickCategory == 3">Alloted Feed PST (Kgs)</th>
                            <th v-if="selectedChickCategory == 2 || selectedChickCategory == 3">Alloted Feed ST (Kgs)</th>
                            <th v-if="selectedChickCategory == 3 && (selectedFormula == 1 || selectedFormula == 2)">Alloted Feed FN1 (Kgs)</th>
                            <th v-if="selectedChickCategory == 3 && selectedFormula == 2">Alloted Feed FN2 (Kgs)</th>
                            <th>Total Alloted Feed (Kgs)</th>
                            <th>Total Remaining Feed (Kgs)</th>
                            <th>Consumed Feed (Kgs)</th>
                            <th>Feed Consumed Per Bird (Kgs)</th>
                        </tr>
                    </thead>
                    <tbody v-if="(loading && !isFeedDataFetched)|| saveStatus || upDateStatus">
                        <tr v-if="loading">
                            <td colspan="19"><loading-bar></loading-bar></td>
                        </tr>
                        <tr v-else>
                            <td colspan="19">No data found</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="(pen, index) in penList" :key="index">
                            <template v-if="index === 0">
                                <td :rowspan="penList.length">
                                    <select style="width: 100px; height: 40px" v-model="dayWeek" @change="fetchFeedConsumptionData()">
                                        <option v-for="day in dayList" :value="day.value" :key="day.value">{{ day.label }}</option>
                                    </select>
                                </td>
                                <!-- <td rowspan="5">
                                    <select v-model="selectedChickCategory" style="width: 110px; height: 40px" @change="feedCalculation()">
                                        <option value="" selected disabled>Select</option>
                                        <option v-for="(chick, chickIndex) in chickCategoryList" :value="chick.id" :key="chickIndex">{{ chick.category_name }}</option>
                                    </select>
                                </td>
                                <td rowspan="5" v-if="selectedChickCategory == 3">
                                    <div style="display: flex; align-items: center;">
                                        <label style="margin-right: 10px; font-size: 14px;">
                                            <input type="radio" v-model="selectedFormula" @change="feedCalculation()" value="1" style="transform: scale(0.8); margin-right: 4px;" />
                                            FN 1
                                        </label>
                                        <label style="font-size: 14px;">
                                            <input type="radio" v-model="selectedFormula" @change="feedCalculation()" value="2" style="transform: scale(0.8); margin-right: 4px;" />
                                            FN 2
                                        </label>
                                    </div>
                                </td> -->
                                <td :rowspan="penList.length">
                                    <select v-model="selectedTrialGroup" @change="fetchFeedConsumptionData()" style="width: 100px; height: 40px">
                                        <option v-for="(item, trialIndex) in trialGroupList" :value="item.id" :key="trialIndex">{{ item.trial_group_id }}</option>
                                    </select>
                                </td>
                            </template>
                            <td class="font-weight-bold">{{ pen.pen_name }}</td>
                            <td><input type="text" v-model="pen.no_of_birds" @input="calculateFeed(index)"></td>
                            <td>
                                <select name="" id="" v-model="pen.feeder" @change="showWarningForChickCategory(), getFeederTareWeight(index, pen.feeder)">
                                    <option value="" selected disabled>Select</option>
                                    <option :value="item.id" v-for="(item, feederIndex) in feederMasterData" :key="feederIndex">{{ item.weight_type }}</option>
                                </select>
                            </td>
                            <td><input type="text" v-model="pen.feeder_with_feed" @input="calculateFeed(index)" @blur="debounceValidateInput(pen.feeder_with_feed, pen.feeder_tare_wt, 'feeder')"></td>
                            <td class="font-weight-bold">{{ pen.feeder_tare_wt || '-'  }}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.remaining_feed_from_feeder) || '-'  }}</td>
                            <td><input type="text" v-model="pen.feeder_drum_with_feed" @input="calculateFeed(index)" @blur="debounceValidateInput(pen.feeder_drum_with_feed, pen.feeder_drum_tare_wt, 'feeder_drum')"></td>
                            <td class="font-weight-bold">{{ pen.feeder_drum_tare_wt || '-'  }}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.remaining_feed_from_feeder_drum) || '-'  }}</td>
                            <td class="font-weight-bold" v-if="selectedChickCategory == 1 || selectedChickCategory == 2 || selectedChickCategory == 3">{{ formatDecimalPoints(pen.alloted_feed_pst) || '-' }}</td>
                            <td class="font-weight-bold" v-if="selectedChickCategory == 2 || selectedChickCategory == 3">{{ formatDecimalPoints(pen.alloted_feed_st) || '-' }}</td>
                            <td class="font-weight-bold" v-if="selectedChickCategory == 3 && (selectedFormula == 1 || selectedFormula == 2)">{{ formatDecimalPoints(pen.alloted_feed_fn1) || '-' }}</td>
                            <td class="font-weight-bold" v-if="selectedChickCategory == 3 && selectedFormula == 2">{{ formatDecimalPoints(pen.alloted_feed_fn2) || '-' }}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.total_alloted_feed) || '-'  }}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.remaining_feed) || '-'  }}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.consumed_feed) || '-'  }}</td>
                            <td class="font-weight-bold">{{ formatDecimalPoints(pen.feed_consumed_per_bird) || '-'  }}</td>
                        </tr>
                    </tbody>
                </md-table>
        
                <div v-if="!loading" class="sec-row">
                    <!-- <div>
                    <md-button @click="addin2" class="md-primary">Add Row</md-button>
                    </div>
                    <div></div> -->
                    <div v-if="showButtons">
                        <md-button v-if="isFeedDataFetched" type="submit" @click.prevent="submit('update')" class="md-success">{{ upDateStatus == true ? 'Updating...' : 'Update' }}</md-button>
                        <md-button v-else type="submit" @click.prevent="submit('save')" class="md-success">{{ saveStatus == true ? 'Saving...' : 'Save' }}</md-button>
                    </div>
                    <div v-if="showButtons && isFeedDataFetched">
                        <!-- <md-button @click.prevent="setActiveTab('view')" class="btn btn-secondary">Cancel</md-button> -->
                        <md-button @click.prevent="fetchFeedConsumptionData()" class="btn btn-secondary">Cancel</md-button>
                    </div>
                    <div v-if="showButtons && !isFeedDataFetched">
                    <md-button type="button" @click.prevent="clearFields('reset')" class="md-danger">Reset</md-button>
                    </div>
                    <!-- @click="resetBw"  -->
                </div>
            </form>
        </md-card-content>
      </md-card>
    </div>
  </template>
  
  <script>
  import LoadingBar from "../components/LoadingBar.vue";
  export default {
    components: {
        LoadingBar,
    },
  props: {
    
  },
  data() {
    return {
        loading: false,
        loadingView: false,
        isFeedDataFetchedView: false,
        activeTab: 'view',
        trials: [],
        selectedTrial: null,
        startDate: '',
        currentDate: '',
        allotedFeedError: [],
        feederMasterData: [],
        trialGroupList: [],
        selectedTrialGroup: null,
        penList: [],
        feedDataView: [],
        chickCategoryList: [],
        disableChickCategoryButton: false,
        selectedChickCategory: '',
        selectedFormula: null,
        selectedChickCategoryView: '',
        selectedFormulaView: null,
        dayWeek: 1,
        dayWeekView: '',
        saveStatus: false,
        upDateStatus: false,
        isFeedDataFetched: false,
        showButtons: false,
        dayList: this.createDayList(),
        availableDays: [],
        allotedFeed: [],
        permissionData: {},
        // debounce: null,
    };
  },
  mounted() {
    this.permissionData = this.$store.getters.getActiveSideBar.permissions[0];
    this.fetchFeederMasterDetails();    
  },
  methods: {
    debounceValidateInput(value, dynamicValue, key){
        // if(this.debounce) clearTimeout(this.debounce);
        // this.debounce = setTimeout(()=>{
                if(Number(value) < Number(dynamicValue)){
                    this.$notify({
                        message: key == 'feeder' ? 'Feeder with Feed Wt cannot be less than feeder tare weight' : 'Feeder drum with Feed Wt cannot be less than feeder drum tare weight',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    })
                };
        // }, 1500);
    },
    showWarningForChickCategory(){
        if(this.selectedChickCategory == '' || this.selectedChickCategory == null || 
            (this.selectedChickCategory == 3 && this.selectedFormula == null)){
            this.$notify({
                        message: 'Please select the Feed Category',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'warning'
                    });
        }
    },
    createDayList() {
        const days = [];
        for (let i = 1; i <= 35; i++) {
            if (i === 7) {
                days.push({ value: i, label: 'week1' });
            } else if (i === 14) {
                days.push({ value: i, label: 'week2' });
            } else if (i === 21) {
                days.push({ value: i, label: 'week3' });
            } else if (i === 28) {
                days.push({ value: i, label: 'week4' });
            } else if (i === 35) {
                days.push({ value: i, label: 'week5' });
            } else {
                days.push({ value: i, label: i.toString() });
            }
        }
        return days;
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
        this.activeTab = tab;
        // this.loading = true;
        this.showButtons = false;
        if(this.activeTab == 'view'){
            this.getAvailableDaysList();
        } else {
            this.fetchTrialGroup();
        }
    },

    fetchFeederMasterDetails(){
        this.loading = true;
        this.loadingView = true;
        axios.get('/feeder-master')
            .then((response) => {
                this.feederMasterData = response.data;
                this.fetchFeederDrumMasterDetails();
            }).catch((error) => {
                this.loading = false;
                this.loadingView = false;
                this.$notify({
                        message: error.response.data.message || 'An error occurred',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    });
                // console.error('Error fetching Feeder details:', error);
            })
    },

    fetchFeederDrumMasterDetails(){
        axios.get('/feeder-drum-master')
            .then((response) => {
                this.feederDrumMasterData = response.data;
                this.fetchChickCategory();
            }).catch((error) => {
                this.loading = false;
                this.loadingView = false;
                this.$notify({
                        message: error.response.data.message || 'An error occurred',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    });
                // console.error('Error fetching Feeder drum details:', error);
            })
    },

    fetchChickCategory(){
        axios.get('/chick-category').then((response) => {
            this.chickCategoryList = response.data;
            this.fetchTrials();
        }).catch((error) => {
            this.loading = false;
            this.loadingView = false;
            this.$notify({
                        message: error.response.data.message || 'An error occurred',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    });
        });
    },

    fetchTrials() {
        this.loading = true;
        this.loadingView = true;
        axios.get('/indous-research/master/trials')
            .then((response) => {
                this.trials = response.data;
                if (this.trials.length > 0) {
                    this.selectedTrial = this.trials[0].id; 
                    this.fetchTrialDetails(); 
                }
            })
            .catch((error) => {
                this.loading = false;
                this.loadingView = false;
            //   console.error('Error fetching trials:', error);
            });
    },
    fetchTrialDetails() {
        this.loading = true;
        this.loadingView = true;
        this.isFeedDataFetchedView = false;
        this.isFeedDataFetched = false;
        axios.get(`/indous-research/master/trial/${this.selectedTrial}`)
            .then((response) => {
                const data = response.data;
                const [day, month, year] = data.start_date.split('-');
                const startDate = new Date(year, month - 1, day);
                this.startDate = startDate.toLocaleDateString('en-GB');
                this.currentDate = new Date().toLocaleDateString('en-GB'); 
                if(this.activeTab == 'view'){
                    this.getAvailableDaysList();
                } else {
                    this.fetchTrialGroup();
                }
            })
            .catch((error) => {
                this.loading = false;
                this.loadingView = false;
            //   console.error('Error fetching trial details:', error);
            });
    },

    getAvailableDaysList(){
        this.loadingView = true;
        this.selectedChickCategoryView = '';
        this.selectedFormulaView = null;
        this.availableDays = [];
        this.dayWeekView = '';
        this.feedDataView = [];
        this.$forceUpdate();
        axios
        .post("/get-days-list", {
          experiment_info_id: this.selectedTrial,
        })
        .then((response) => {
          this.availableDays = response.data;
          this.dayWeekView = this.availableDays.length > 0 ? this.availableDays[0].value : '';
          this.fetchViewFeedData();
        })
        .catch((error) => {
            this.loading = false;
            this.loadingView = false;
        //   console.error("Error fetching days:", error);
        });
    },

    fetchViewFeedData(){
        this.loadingView = true;
        this.feedDataView = [];
        this.$forceUpdate();
        this.isFeedDataFetchedView = false;
        this.selectedChickCategoryView = '';
        this.selectedFormulaView = null;
        if(this.selectedTrial && this.dayWeekView){
            axios.get(`/show-feed-consumed-data?expId=${this.selectedTrial}&dayWeek=${this.dayWeekView}`)
            .then((response) => {
                this.feedDataView = response.data;
                this.selectedChickCategoryView = this.feedDataView[0].data[0].chick_category;
                this.selectedFormulaView = this.feedDataView[0].data[0].formula;
                this.isFeedDataFetchedView = true;      
                this.feedDataView.forEach((feedData) => {
                    feedData.data.forEach( penData => {
                        penData.feeder_with_feed = this.formatDecimalPoints(penData.feeder_with_feed);
                        penData.feeder_drum_with_feed = this.formatDecimalPoints(penData.feeder_drum_with_feed);
                        this.feederMasterData.forEach( feederData => {
                            if(feederData.id == penData.feeder){
                                penData.feeder_tare_wt = feederData.weight;
                                this.$forceUpdate();
                            }
                        });
                    })
                });
                this.getFeederDrumTareWeightForViewPage();
            })
            .catch((error) => {
                this.feedDataView = [];
                this.$forceUpdate();
                this.isFeedDataFetchedView = false;
                this.loadingView = false;
            })
        }
    },

    async getFeederDrumTareWeightForViewPage(){
        this.loadingView = true;
        if (this.selectedChickCategoryView != null) {
            this.feedDataView.forEach( feedData => {
                feedData.data.forEach((pen) => {
                    this.feederDrumMasterData.forEach( drumData => {
                        if(drumData.id == pen.feeder_drum){
                            if (this.selectedChickCategoryView == 1) {
                                pen.feeder_drum_tare_wt = drumData.pst;
                                this.$forceUpdate();
                            }
                            else if (this.selectedChickCategoryView == 2) {
                                pen.feeder_drum_tare_wt = drumData.st;
                                this.$forceUpdate();
                            } 
                            else if (this.selectedChickCategoryView == 3) {
                                if (this.selectedFormulaView == 1) {
                                    pen.feeder_drum_tare_wt = drumData.fn1;
                                    this.$forceUpdate();
                                } 
                                else if (this.selectedFormulaView == 2) {
                                    pen.feeder_drum_tare_wt = drumData.fn2;
                                    this.$forceUpdate();
                                }
                            }        
                        }
                    })
                    this.$forceUpdate();
                });
            })
        }
        this.loadingView = false;
    },

    fetchTrialGroup(){
        if(this.selectedTrial != null){
            this.loading = true;
            this.selectedChickCategory = '';
            this.selectedFormula = null;
            this.dayWeek = 1;
            this.penList = [];
            this.$forceUpdate();
            axios.get(`/indous-research/master/trial-group-list?experiment_info_id=${this.selectedTrial}`).then(response=>{
                this.trialGroupList = response.data;
                this.selectedTrialGroup = this.trialGroupList[0].id;
                this.fetchFeedConsumptionData();
            })
            .catch(error=>{
                this.showButtons = false;
                this.loading = false;
                this.$notify({
                                message: error.response.data.message || 'An error occurred',
                                horizontalAlign: 'right',
                                verticalAlign: 'top',
                                type: 'danger'
                            });
            });
        }
    },

    fetchFeedConsumptionData(){
        if(this.selectedTrial && this.dayWeek && this.selectedTrialGroup){
            this.penList = [];
            this.$forceUpdate();
            this.selectedChickCategory = '';
            this.selectedFormula = null;
            this.loading = true;
            this.disableChickCategoryButton = false;
            this.showButtons = false;
            this.isFeedDataFetched = false;
            // this.selectedChickCategory = '';
            this.selectedFormula = null;
            axios.get(`/feed-consumption-data?expId=${this.selectedTrial}&trialId=${this.selectedTrialGroup}&dayWeek=${this.dayWeek}`)
            .then((response) => {
                this.penList = response.data;
                this.selectedChickCategory = this.penList[0].chick_category;
                this.selectedFormula = this.penList[0].formula;
                this.penList.forEach((pen) => {
                    pen.feeder_with_feed = this.formatDecimalPoints(pen.feeder_with_feed);
                    pen.feeder_drum_with_feed = this.formatDecimalPoints(pen.feeder_drum_with_feed);
                    this.feederMasterData.forEach( feederData => {
                        if(feederData.id == pen.feeder){
                            pen.feeder_tare_wt = feederData.weight;
                            this.$forceUpdate();
                        }
                    });
                });
                this.isFeedDataFetched = true;      
                this.showButtons = true;
                this.getFeederDrumTareWeight();
            })
            .catch((error) => {
                this.showButtons = false;
                this.isFeedDataFetched = false;
                this.clearFields();
                this.fetchTrialGroupPen();
                // console.error('Error fetching feed consumption data', error);
            })
        }
    },

    fetchTrialGroupPen(){
        this.loading = true;
        this.selectedChickCategory = '';
        this.selectedFormula = null;
        // this.selectedChickCategory = this.dayWeek <= 7 ? 1 : this.dayWeek <= 21 ? 2 : 3;
        // this.selectedFormula = this.selectedChickCategory == 3 && this.dayWeek > 28 ? 2 : 1;
        axios.get(`/trial-group-pen-list?trial_number=${this.selectedTrial}&trial_group_id=${this.selectedTrialGroup}&day_week=${this.dayWeek}`)
        .then((response) => {
            this.allotedFeedError = [];
            this.penList = response.data.penList;
            this.showButtons = true;
            this.birdCount = response.data.birdCount;
            const chickCategory = response.data.chick_category;
            if(chickCategory != null){
                this.selectedChickCategory = chickCategory;
                this.disableChickCategoryButton = true;
            }
            this.penList.forEach((pen, penIndex) => {
                pen.no_of_birds = Array.isArray(this.birdCount) ? (this.birdCount.length > 0 ? this.birdCount[penIndex].no_of_birds : '') : this.birdCount;
                pen.feeder = '';
                pen.feeder_with_feed = '';
                pen.feeder_tare_wt = '';
                pen.remaining_feed_from_feeder = '';
                pen.feeder_drum_with_feed = '';
                pen.feeder_drum_tare_wt = '';
                pen.feeder_drum = '';
                pen.remaining_feed_from_feeder_drum = '';
                pen.alloted_feed_pst = '';
                pen.alloted_feed_st = '';
                pen.alloted_feed_fn1 = '';
                pen.alloted_feed_fn2 = '';
                pen.total_alloted_feed = '';
                pen.consumed_feed = '';
                pen.feed_consumed_per_bird = '';
                pen.remaining_feed = '';
            });
            this.getFeederDrumTareWeight();
        })
        .catch((error) => {
            this.loading = false;
            this.showButtons = false;
            this.$notify({
                        message: 'Kindly assign pens in corrected feed formula',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    });
            // console.error('Error fetching trial details:', error);
        });
    },

    async getFeederDrumTareWeight(){
        this.loading = true;
        this.allotedFeed = [];
        this.allotedFeedError = [];
        if (this.selectedChickCategory == 1 || this.selectedChickCategory == 2) {
            this.selectedFormula = null;
        }
        if(!this.isFeedDataFetched){
            await this.fetchAllotedFeed();
        }
        if (this.selectedChickCategory != null) {
            this.penList.forEach((pen, penIndex) => {
                this.feederDrumMasterData.forEach( drumData => {
                    if(drumData.id == pen.pen_id){
                        pen.feeder_drum_tare_wt = '';
                        pen.feeder_drum = drumData.id;
                        if (this.selectedChickCategory == 1) {
                            pen.feeder_drum_tare_wt = drumData.pst;
                            this.$forceUpdate();
                        } else if (this.selectedChickCategory == 2) {
                            pen.feeder_drum_tare_wt = drumData.st;
                            this.$forceUpdate();
                        } else if (this.selectedChickCategory == 3) {
                            if (this.selectedFormula == 1) {
                                pen.feeder_drum_tare_wt = drumData.fn1;
                                this.$forceUpdate();
                            } else if (this.selectedFormula == 2) {
                                pen.feeder_drum_tare_wt = drumData.fn2;
                                this.$forceUpdate();
                            }
                        }
                    }
                })
                if(!this.isFeedDataFetched){
                    pen.total_alloted_feed = pen.alloted_feed_pst = pen.alloted_feed_st = pen.alloted_feed_fn1 = pen.alloted_feed_fn2 = '';
                    if(this.allotedFeed.length > 0){
                        this.allotedFeed.forEach(item => {
                            if(item.chickCategory == 1){
                                pen.alloted_feed_pst = item.allotedFeed;
                                this.$forceUpdate();
                            } else if(item.chickCategory == 2){
                                pen.alloted_feed_st = item.allotedFeed;
                                this.$forceUpdate();
                            } else if(item.chickCategory == 3 && item.formula == 1){
                                pen.alloted_feed_fn1 = item.allotedFeed;
                                this.$forceUpdate();
                            } else if(item.chickCategory == 3 && item.formula == 2){
                                pen.alloted_feed_fn2 = item.allotedFeed;
                                this.$forceUpdate();
                            }
                        })
                    }
                    pen.total_alloted_feed = (Number(pen.alloted_feed_pst) || 0) + (Number(pen.alloted_feed_st) || 0) + (Number(pen.alloted_feed_fn1) || 0) + (Number(pen.alloted_feed_fn2) || 0);
                }
                this.$forceUpdate();
            });
        };
        this.loading = false;
    },
    

    calculateRemainingFeedFromFeeder(penIndex){
        if(this.penList[penIndex].feeder_with_feed && this.penList[penIndex].feeder_tare_wt){
            this.penList[penIndex].remaining_feed_from_feeder = (Number(this.penList[penIndex].feeder_with_feed) - Number(this.penList[penIndex].feeder_tare_wt));
            this.$forceUpdate();
        } else {
            this.penList[penIndex].remaining_feed_from_feeder = '';
            this.$forceUpdate();
        }
    },
    calculateRemainingFeedFromFeederDrum(penIndex){
        if(this.penList[penIndex].feeder_drum_with_feed && this.penList[penIndex].feeder_drum_tare_wt){
            this.penList[penIndex].remaining_feed_from_feeder_drum = (Number(this.penList[penIndex].feeder_drum_with_feed) - Number(this.penList[penIndex].feeder_drum_tare_wt));
            this.$forceUpdate();
        } else {
            this.penList[penIndex].remaining_feed_from_feeder_drum = '';
            this.$forceUpdate();
        }
    },
    calculateFeed(penIndex){
        this.calculateRemainingFeedFromFeeder(penIndex);
        this.calculateRemainingFeedFromFeederDrum(penIndex);
        if (this.penList[penIndex].remaining_feed_from_feeder !== null && this.penList[penIndex].remaining_feed_from_feeder !== '' && this.penList[penIndex].remaining_feed_from_feeder >= 0 &&
            this.penList[penIndex].remaining_feed_from_feeder_drum !== null && this.penList[penIndex].remaining_feed_from_feeder_drum !== '' && this.penList[penIndex].remaining_feed_from_feeder_drum >= 0) {
            this.penList[penIndex].remaining_feed = (Number(this.penList[penIndex].remaining_feed_from_feeder) + Number(this.penList[penIndex].remaining_feed_from_feeder_drum));
            this.penList[penIndex].consumed_feed = (Number(this.penList[penIndex].total_alloted_feed) - Number(this.penList[penIndex].remaining_feed));
            this.$forceUpdate();
            if(this.penList[penIndex].no_of_birds > 0){
                this.penList[penIndex].feed_consumed_per_bird = (Number(this.penList[penIndex].consumed_feed) / Number(this.penList[penIndex].no_of_birds)).toFixed(3);
                this.$forceUpdate();
            } else {
                this.penList[penIndex].feed_consumed_per_bird = '';
                this.$forceUpdate();
            }
        } else {
            this.penList[penIndex].remaining_feed = '';
            this.penList[penIndex].consumed_feed = '';
            this.penList[penIndex].feed_consumed_per_bird = '';
            this.$forceUpdate();
        }
        
    },

    async fetchAllotedFeed(){
        this.loading = true;
        this.allotedFeedError = [];
        try {
            const response = await axios.get(`/fetch-allocated-feed?expId=${this.selectedTrial}&trialGroupId=${this.selectedTrialGroup}&chick_category=${this.selectedChickCategory}&formula=${this.selectedFormula}`)
            const expectedLengths = {
                1: 1,
                2: 2,
                3: {
                    1: 3,
                    2: 4
                }
            };

            let expectedLength = this.selectedChickCategory === 3
                ? expectedLengths[3][this.selectedFormula]
                : expectedLengths[this.selectedChickCategory];

            if (response.data.length === expectedLength) {
                this.allotedFeed = response.data;
            } else {
                this.allotedFeedError.push('Please perform the allotment for Pre-Starter, Starter, and Finisher in the Corrected Feed Formula');
            }

            if (this.allotedFeedError.length > 0) {
                this.$notify({
                    message: this.allotedFeedError[0] || 'An error occurred',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'danger'
                });
            }
            
        } catch (error) {
            this.loading = false;
            // console.error('Error fetching Alloted feed data ', error);
        }
    },

    async feedCalculation(){
        this.loading = true;
        await this.getFeederDrumTareWeight();
        this.penList.forEach((pen, penIndex) => {
            this.calculateFeed(penIndex);
        })
    },

    getFeederTareWeight(penIndex, feederId){
        this.feederMasterData.forEach( item => {
            if(item.id == feederId){
                this.penList[penIndex].feeder_tare_wt = item.weight;
                this.$forceUpdate();
            }
        });
        this.calculateFeed(penIndex);
    },

    formatDecimalPoints(value) {
      if (value !== null && value !== undefined && value !== "") {
        return Number(value) % 1 === 0
          ? Number(value).toFixed(0)
          : Number(value).toFixed(3);
      }
      return "";
    },

    convertKgToGrams(kg) {
        const kgNumber = Number(kg);
        
        if (isNaN(kgNumber)) {
            throw new Error('Input must be a number or a valid numeric string');
        }
        return kgNumber * 1000;
    },
    
    clearFields(){
        if(this.penList.length > 0){
            this.penList.forEach(pen => {
                pen.chick_category = '';
                pen.feeder = '';
                pen.feeder_with_feed = '';
                pen.feeder_tare_wt = '';
                pen.remaining_feed_from_feeder = '';
                pen.feeder_drum_with_feed = '';
                pen.feeder_drum = '';
                pen.remaining_feed_from_feeder_drum = '';
                pen.consumed_feed = '';
                pen.feed_consumed_per_bird = '';
                pen.remaining_feed = '';
                this.$forceUpdate();
            });
        }
    },

    submit(value) {
        const saveData = {};
        saveData.dayWeek = this.dayWeek;
        saveData.trialGroup = this.selectedTrialGroup;
        saveData.penData = this.penList;
        saveData.experimentId = this.selectedTrial;
        saveData.chickCategory = this.selectedChickCategory;
        saveData.formula = this.selectedFormula;
        
        if(value == 'update') {
            this.upDateStatus = true;
            axios
                .put("/feed-consumption-per-bird-update", saveData)
                .then((response) => {
                    this.upDateStatus = false;
                    this.$notify({
                        message: response.data.message,
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'success'
                    });
                })
                .catch((error) => {
                    this.upDateStatus = false;
                    this.errors = Object.values(error.response.data.errors).flat();
                    this.$notify({
                        message: error.response.data.error || this.errors[0] || 'An error occurred',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    });
                });
        }
        else if(value == 'save'){
            this.saveStatus = true;
            axios
                .post("/feed-consumption-per-bird-save", saveData)
                .then((response) => {
                    this.saveStatus = false;
                    this.$notify(
                        {
                            message:response.data.message,
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'success'
                        })
                    this.fetchFeedConsumptionData();
                })
                .catch((error) => {
                    this.saveStatus = false;
                    this.errors = Object.values(error.response.data.errors).flat();
                    this.$notify({
                        message: error.response.data.error || this.errors[0] || 'An error occurred',
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    });
                });
        }
    },
  }
};

</script>
<style scoped>
.checkbox-select__trigger.isActive {
    border-radius: 10px 10px 0 0;
    background: #f2f2f2;
}
.readonly-select {
    pointer-events: none; 
    background-color: #fff; 
    color: #000; 
    border: 1px solid #ced4da; 
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.2;
    appearance: none; 
    -moz-appearance: none;
    -webkit-appearance: none;
}
.readonly-select::after {
    content: "▼";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; 
}
table input {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
}
</style>