<template>
  <div>
    <div class="row align-items-center mb-3">
      <div class="col">
        <h4 class="mt-0 header-title"><span class="md-body-2"></span></h4>
      </div>

      <div class="">
        <div class="col-auto">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a
                href="#"
                :class="['nav-link', { active: isActive('list') }]"
                @click.prevent="setActiveTab('list'), clearFields()"
              >
                List
              </a>
            </li>
            <li class="nav-item" v-if="add">
              <a
                href="#"
                :class="['nav-link', { active: isActive('create') }]"
                @click.prevent="setActiveTab('create'),clearFields()"
              >
                {{viewMode ? 'View' :'Create'}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="activeTab === 'create'">
      <div class="page">
        <md-card>
          <div id="form"></div>
          <md-card-header data-background-color="green">
            <h4 class="title">EXPERIMENT INFORMATION</h4>
          </md-card-header>
          <md-card-content>
            <md-card-content style="padding: 15px 60px">
              <form @submit.prevent="submit()">
                <div class="expinfo">
                  <div>
                    <div class="first-row">
                      <div>
                        <label><span class="md-body-2">Start Date</span></label>
                        <md-field v-if="viewMode || isUpdate">
                          <md-input v-model="startDate" readonly></md-input>
                        </md-field>
                        <md-datepicker
                        v-else
                          v-model="startDate"
                          value="string"
                          md-immediately
                        />
                        <validation
                          :errors="errors"
                          field="start_date"
                        ></validation>
                      </div>

                      <div>
                        <label><span class="md-body-2">Trial No</span></label>
                        <md-field>
                          <md-input v-model="trialNo" readonly></md-input>
                        </md-field>
                      </div>
                    </div>
                  </div>

                  <div></div>

                  <div class="first-row">
                    <div>
                      <md-card-header
                        data-background-color="green"
                        style="
                          height: 30px;
                          padding: 0px 0px 0px 5px;
                          margin: 0px;
                        "
                      >
                        <h5 class="title">Aim of the Experiment</h5>
                      </md-card-header>
                      <div class="textarea-container">
                        <textarea
                         :readonly="viewMode"
                          id="limited-textarea"
                          v-model="aim"
                          maxlength="300"
                          rows="4"
                          cols="68"
                          @input="clearErrors()"
                        ></textarea>
                        <div>{{ aim.length }}/300 characters</div>
                        <validation
                          :errors="errors"
                          field="aim_of_exp"
                        ></validation>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <md-card-header
                        data-background-color="green"
                        style="
                          height: 30px;
                          width: 512px;
                          padding: 0px 0px 0px 5px;
                          margin: 0px;
                        "
                      >
                        <h5 class="title">Methodology</h5>
                      </md-card-header>
                      <textarea
                        id="limited-textarea"
                        :readonly="viewMode"
                        v-model="methodology"
                        maxlength="300"
                        rows="4"
                        cols="68"
                        @input="clearErrors()"
                      ></textarea>
                      <div>{{ methodology.length }}/300 characters</div>
                      <validation
                        :errors="errors"
                        field="methodology"
                      ></validation>
                    </div>
                  </div>

                  <div class="">
                    <md-card-header
                      data-background-color="green"
                      style="
                        height: 30px;
                        width: 510px;
                        padding: 0px 0px 0px 5px;
                        margin: 0px;
                      "
                    >
                      <h5 class="title">Treatment Details</h5>
                    </md-card-header>

                    <div class="sec-3">
                      <div
                        v-for="(group, index) in groupList"
                        :key="index"
                        class="row mb-2"
                      >
                        <div class="col-md-7 d-flex">
                          <div class="text-nowrap">
                            <label class="font-weight-bold mt-2">
                              <span>T{{ index + 1 }} :</span>
                            </label>
                          </div>

                          <div v-if="!isUpdate" class="d-flex w-100">
                            <input
                              type="text"
                              class="form-control ml-4"
                              v-model="group.control_name"
                              @input="clearErrors(), (groupValidationMsg = '')"
                              required
                            />
                            <!-- Clear icon, shown only when not in update mode and on the last group -->
                            <div
                              @click="removeRow(group)"
                              class="my-auto mx-2"
                              style="cursor: pointer"
                              v-if="
                                groupList.length > 1 &&
                                groupList.length == index + 1
                              "
                            >
                              <md-icon style="font-size: 10px">clear</md-icon>
                            </div>
                          </div>

                          <!-- Show static data when in update mode -->
                          <div class="py-auto" v-else>
                            <p class="ml-4 mt-2">{{ group.control_name }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="row" v-if="!isUpdate">
                        <div class="col-md-5">
                          <p class="text-danger">{{ groupValidationMsg }}</p>
                        </div>
                      </div>

                      <!-- Add Row Button -->
                      <div
                        v-if="groupList.length < 6 && !isUpdate"
                        class="d-flex justify-content-center pt-4"
                      >
                        <button type="button" @click="addRow">Add Row</button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <md-card-header
                      data-background-color="green"
                      style="
                        height: 30px;
                        width: 510px;
                        padding: 0px 0px 0px 5px;
                        margin: 0px;
                      "
                    >
                      <h5 class="title">Materials and Methods</h5>
                    </md-card-header>
                    <div class="sec-3">
                      <div class="struct-last">
                        <label for="country"
                          ><span class="md-body-2">Chicks</span></label
                        >
                        <label for="country"
                          ><span class="md-body-2">Batch</span></label
                        >

                        <div>
                          <input
                            type="text"
                            :readonly="viewMode"
                            v-model="chicks"
                            id="fname"
                            name="fname"
                            style="width: 240px"
                            @input="clearErrors()"
                          />
                          <validation
                            :errors="errors"
                            field="chicks"
                          ></validation>
                          <br /><br />
                        </div>
                        <div>
                          <input
                            type="text"
                            v-model="batch"
                            :readonly="viewMode"
                            id="fname"
                            name="fname"
                            style="width: 240px"
                            @input="clearErrors()"
                          />

                          <validation
                            :errors="errors"
                            field="batch"
                          ></validation>
                          <br /><br />
                        </div>
                      </div>
                      <div class="struct-last">
                        <label for="country"
                          ><span class="md-body-2">Description:</span></label
                        >
                        <label for="country"
                          ><span class="md-body-2">Premix:</span></label
                        >
                        <div>
                          <textarea
                            id="limited-textarea"
                            v-model="description"
                            maxlength="300"
                            :readonly="viewMode"
                            rows="6"
                            cols="30"
                            @input="clearErrors()"
                          ></textarea>
                          <span>{{ description.length }}/300 characters</span>
                          <validation
                            :errors="errors"
                            field="description"
                          ></validation>
                        </div>
                        <div>
                          <textarea
                            id="limited-textarea"
                            v-model="premix"
                            :readonly="viewMode"
                            maxlength="300"
                            rows="6"
                            cols="30"
                            @input="clearErrors()"
                          ></textarea>
                          <span>{{ premix.length }}/300 characters</span>

                          <validation
                            :errors="errors"
                            field="premix"
                          ></validation>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end" v-if="!viewMode">
                  <div id="buttons">
                    <div v-if="isUpdate">
                      <md-button
                        type="button"
                        @click="exportPdf"
                        class="md-secondary"
                        >Export to PDF</md-button
                      >
                    </div>
                    <div  style="padding-left: 10px">
                      <md-button
                        v-if="!isUpdate"
                       
                        type="submit"
                        class="md-success"
                        :disabled="requestStatus"
                        >{{ requestStatus ? "Saving..." : "Save" }}</md-button
                      >
                      <md-button
                        v-else
                        @click="update()"
                        type="button"
                        class="md-success"
                        :disabled="requestStatus"
                        >{{
                          requestStatus ? "Updating..." : "Update"
                        }}</md-button
                      >
                    </div>
                    <div style="padding-left: 10px">
                      <md-button
                        type="button"
                        @click="clearFields(), setActiveTab('list')"
                        class="md-danger"
                        >Cancel</md-button
                      >
                    </div>
                  
                  </div>
                </div>
                 <div class="row justify-content-end" v-else>
                       <md-button
                        type="button"
                        @click="exportPdf"
                        class="md-secondary"
                        >Export to PDF</md-button
                      >
                      <md-button
                        type="button"
                        @click="clearFields(), setActiveTab('list')"
                        class="md-secondary"
                        >Back</md-button
                      >
                
                 </div>
              </form>
            </md-card-content>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
        <div class="col">
          <label for="movie" class="custom-input-sm"
            >Show
            <select
              name=""
              class="form-control"
              id=""
              v-model="paginate"
              @change="onPaginate(1)"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="">All</option>
            </select></label
          >
        </div>

        <md-field class="custom-md-field" style="margin: 0px 0px 0px 0px">
          <label>Search...</label>
          <md-input
            v-model="search"
            @input="$debounce(onChangeFilter)"
          ></md-input>
        </md-field>
        <md-icon>search</md-icon>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">EXPERIMENT INFO</h4>
          </md-card-header>

          <md-card-content>
            <md-table
              :table-header-color="tableHeaderColor"
              class="custom-table"
            >
              <tr>
                <th class="py-2">Sl No</th>
                <th class="py-2">Start Date</th>
                <th class="py-2">Trial No</th>
                <th class="py-2">Aim of the experiment</th>
                <th class="py-2">Methodology</th>
                <th class="py-2">Chicks</th>
                <th class="py-2">Batch</th>
                <th class="py-2">Description</th>
                <th class="py-2">Premix</th>
                <th class="py-2">Action</th>
              </tr>

              <tr
                v-for="(item, index) in experimentInfoList?.data"
                :key="item.id"
              >
                <td>{{ experimentInfoList.from + index }}</td>
                <td class="text-nowrap">{{ item.start_date }}</td>
                <td class="text-nowrap">{{ item.trial_no }}</td>
                <td class="text-left">{{ item.aim_of_exp }}</td>
                <td class="text-left">{{ item.methodology }}</td>
                <td class="text-nowrap">{{ item.chicks }}</td>
                <td class="text-nowrap">{{ item.batch }}</td>
                <td class="text-left">{{ item.description }}</td>
                <td class="text-left">{{ item.premix }}</td>
                <td>
                   <button
                  v-if="index > 0 || experimentInfoList.current_page > 1"
                    @click="onEdit(item,'view')"
                    
                  >
                    <i class="mdi mdi-eye mx-auto"></i>
                  </button>
                  <button
                   v-else-if="edit"
                    @click="onEdit(item)"
                    :disabled="index > 0 || experimentInfoList.current_page > 1"
                  >
                    <i class="mdi mdi-pencil mx-auto"></i>
                  </button>
                 
                </td>
              </tr>
            </md-table>
            <loading-bar v-if="load"></loading-bar>
          </md-card-content>
          <div class="row px-3">
            <div class="col-md-6">
              Showing
              {{ experimentInfoList.from ? experimentInfoList.from : 0 }}
              to
              {{ experimentInfoList.to ? experimentInfoList.to : 0 }} of
              {{ experimentInfoList.total ? experimentInfoList.total : 0 }}
              entries
            </div>
            <div class="col-md-6">
              <pagination
                :pagination="experimentInfoList"
                :offset="4"
                @paginate="onPaginate"
              ></pagination>
            </div>
          </div>
        </md-card>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Pagination from "../components/Pagination.vue";
import LoadingBar from "../components/LoadingBar.vue";
import { jsPDF } from "jspdf";
import "jspdf-autotable"; // For table generation

export default {
  components: { Pagination, LoadingBar },

  name: "ExperimentInfo",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      groupList: [{ trial_group_id: 1, control_name: "" }], // Initialize with one group (T1)

      id: "",
      search: "",

      startDate: "",
      trialNo: "",
      aim: "",
      methodology: "",

      t1Group: "",
      t2Group: "",
      t3Group: "",
      t4Group: "",
      t5Group: "",
      t6Group: "",

      chicks: "",
      batch: "",
      description: "",
      premix: "",

      groupValidationMsg: "",

      activeTab: "list", // Default active tab
      controlList: [],
      errors: {},
      experimentInfoList: {
        data: [],
        total: 5,
        per_page: 5,
        last_page: 1,
        from: 1,
        to: 1,
        current_page: 1,
      },
      paginate: 5,
      load: false, // for loader
      isUpdate: false, // for update button
      requestStatus: false, // disable button once clicked
      add: false,
      edit: false,
      viewMode:false
    };
  },
  mounted() {
    this.add = this.$store.getters.getActiveSideBar.permissions[0].add;
    this.edit = this.$store.getters.getActiveSideBar.permissions[0].edit;
    this.getTrialNo();
    // this.fetchControls();
    this.fetchExperimentInfoList();
    this.$material.locale.dateFormat = "dd-MM-yyyy";
  },
  watch: {
    startDate: function (value) {
      this.startDate = value === null ? "" : value;
      if (this.isUpdate) return;
      this.getTrialNo();
      this.clearErrors();
    },
  },
  // computed: {
  //   startDateFormatted() {
  //     if (!this.startDate) return "";
  //     const [year, month, day] = this.startDate.split("-");
  //     return `${day}-${month}-${year}`;
  //   },
  // },
  methods: {
    addRow() {
      let length = this.groupList.length;
      // let lastItem = this.groupList[length - 1];

      if (!this.groupList[length - 1][`control_name`]) {
        this.groupValidationMsg = "please enter control name";
        return;
      }

      if (this.groupList.length < 6) {
        // Push the new object with the dynamic key into groupList
        this.groupList.push({
          trial_group_id: this.groupList.length + 1,
          control_name: "",
        });
      }
    },

    removeRow(row) {
      // this.groupList.splice(index, 1);
      this.groupList = this.groupList.filter((i) => i !== row);

      this.groupValidationMsg = "";
    },

    isActive(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.isUpdate = false;
    },
    toggleEdit(item) {
      item.isEditing = !item.isEditing;
    },
    saveChanges(item) {
      item.isEditing = false;
      // Additional logic to save changes if needed
    },

    getTrialNo() {
      if (!this.startDate) return;

      axios
        .get(`/indous-research/experiment-information/get-trial-no`, {
          params: {
            startDate: this.startDate,
          },
        })
        .then((response) => {
          this.trialNo = response.data.finalTrialNum;
        });
    },

    onEdit(item,mode ='edit') {
      // display saved data in fields
      this.setActiveTab("create");
      this.id = item.id;
      this.startDate = item.start_date;
      this.trialNo = item.trial_no;
      this.aim = item.aim_of_exp;
      this.methodology = item.methodology;

      // let groups = [
      //   item.tg1_control,
      //   item.tg2_control,
      //   item.tg3_control,
      //   item.tg4_control,
      //   item.tg5_control,
      //   item.tg6_control,
      // ];
      // console.log(groups);

      this.groupList = item.trialgroupmap;

      // groups.forEach((item, index) => {
      //   // Push the new object with the dynamic key into groupList
      //   if (item) {
      //     const keyName = `tg${index + 1}_control`;
      //     this.groupList.push({ [keyName]: item });
      //   }
      // });

      this.chicks = item.chicks;
      this.batch = item.batch;
      this.description = item.description;
      this.premix = item.premix;

     if(mode=='view') this.viewMode = true;
   
      this.isUpdate = true; // display update button if true else submit
     
    },
    exportPdf() {
      const doc = new jsPDF();
      
      // Get the width and height of the PDF document
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const borderMargin = 15; // Margin from the edges for border
      const padding = 6; // Padding inside the border
      const contentMargin = borderMargin + padding; // Total margin for content
      const textWidth = pageWidth - 2 * contentMargin; // Available width for text

      // Add a thinner border around the page
      doc.setLineWidth(0.5); // Set the thinner border thickness
      doc.rect(borderMargin, borderMargin, pageWidth - 2 * borderMargin, pageHeight - 2 * borderMargin); // Draw rectangle (x, y, width, height)

      // Add title
      doc.setFontSize(18);
      const title = "INDOUS RESEARCH";
      const titleWidth = doc.getTextWidth(title);
      const titleX = (pageWidth - titleWidth) / 2;
      doc.text(title, titleX, contentMargin+5); // Centered title

      // Add start date and trial number in one row
      doc.setFontSize(12);
      doc.text(`Start Date: ${this.startDate}`, contentMargin, contentMargin + 15);
      doc.text(`Trial No: ${this.trialNo}`, pageWidth - contentMargin - 45, contentMargin + 15); // Adjusted position

      // Add aim with text wrapping
      doc.setFontSize(12);
      doc.text("Aim of the experiment:", contentMargin, contentMargin + 27);
      doc.text(this.aim, contentMargin + 8, contentMargin + 34, { maxWidth: textWidth - 20 });

      // Add methodology with text wrapping
      doc.text("Methodology:", contentMargin, contentMargin + 47);
      doc.text(this.methodology, contentMargin + 8, contentMargin + 55, { maxWidth: textWidth - 20 });

      // Add table with borders and no background color
      const tableData = this.groupList;

      doc.autoTable({
        head: [['Trial Group', 'Control']], // Table headers
        body: tableData.map(row => ['T'+row.trial_group_id, row.control_name]), // Table data
        startY: contentMargin + 70, // Start table after some space
        margin: { left: contentMargin, right: contentMargin },
        styles: {
          fillColor: [255, 255, 255], // No background color (white)
          textColor: [0, 0, 0], // Black text color
          lineWidth: 0.1, // Thin border for cells
          lineColor: [0, 0, 0], // Black border color
        },
        tableLineColor: [0, 0, 0], // Black border around table
        tableLineWidth: 0.1, // Thin border width around table
        headStyles: {
          fillColor: [255, 255, 255], // White background for the header
          textColor: [0, 0, 0], // Black text in the header
          lineWidth: 0.1, // Thin header border
          lineColor: [0, 0, 0] // Black header border color
        },
        bodyStyles: {
          lineWidth: 0.1, // Thin cell borders
          lineColor: [0, 0, 0] // Black cell border color
        }
      });

      // Add chicks and batch below the table
      const finalY = doc.autoTable.previous.finalY || contentMargin + 80; // Get position after the table
      doc.text(`Chicks: ${this.chicks}`, contentMargin, finalY + 15);
      doc.text(`Batch: ${this.batch}`, contentMargin + 100, finalY + 15);

      // Add description with text wrapping
      doc.text("Description:", contentMargin, finalY + 27);
      doc.text(this.description, contentMargin + 8, finalY + 35, { maxWidth: textWidth - 20 });

      // Add premix with text wrapping
      doc.text("Premix:", contentMargin, finalY + 57);
      doc.text(this.premix, contentMargin + 8, finalY + 64, { maxWidth: textWidth - 20 });

      // Save the PDF
      doc.save("experiment-info.pdf");
    },
    update() {
      if (this.hasEmptyValues(this.groupList)) {
        this.groupValidationMsg = "please enter control name";
        return;
      }

      this.requestStatus = true;
      axios
        .put("/indous-research/experiment-information/" + this.id, {
          start_date: this.startDate,
          trial_no: this.trialNo,
          aim_of_exp: this.aim,
          methodology: this.methodology,
          trial_group_list: JSON.stringify(this.groupList),

          chicks: this.chicks,
          batch: this.batch,
          description: this.description,
          premix: this.premix,
        })
        .then((response) => {
          this.requestStatus = false;
          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.clearFields();
          this.setActiveTab("list");
        })
        .catch((error) => {
          this.requestStatus = false;
          this.errors = error.response.data.errors;
        });
    },

    clearErrors() {
      this.errors = {};
    },

    clearFields() {
      // clear form fields after submit or update
       this.viewMode = false;
      this.startDate =
        this.trialNo =
        this.aim =
        this.methodology =
        this.chicks =
        this.batch =
        this.description =
        this.premix =
        this.search =
        this.groupValidationMsg =
          "";
       

      (this.groupList = [{ trial_group_id: 1, control_name: "" }]),
        // this.clearControls();
        this.clearErrors();
      this.fetchExperimentInfoList();
    },

    fetchExperimentInfoList() {
      this.load = true;
      this.experimentInfoList.data = [];
      axios
        .get(
          `/indous-research/experiment-information?search=${this.search}&paginate=${this.paginate}&page=${this.experimentInfoList.current_page}`
        )
        .then((response) => {
          this.experimentInfoList = response.data.experimentInfoList;
          this.load = false;
        });
    },

    onPaginate(page) {
      this.experimentInfoList.current_page = page;

      this.fetchExperimentInfoList();
    },
    onChangeFilter(page = 1) {
      // page number
      this.experimentInfoList.current_page = page;

      this.fetchExperimentInfoList();
    },
    hasEmptyValues(arr) {
      return arr.some((obj) => !obj.control_name.replace(/\s/g, ""));
    },
    submit() {
      if (this.hasEmptyValues(this.groupList)) {
        this.groupValidationMsg = "please enter control name";
        return;
      }

      this.requestStatus = true;
      axios
        .post("/indous-research/experiment-information", {
          start_date: this.startDate,
          trial_no: this.trialNo,
          aim_of_exp: this.aim,
          methodology: this.methodology,
          trial_group_list: JSON.stringify(this.groupList),

          chicks: this.chicks,
          batch: this.batch,
          description: this.description,
          premix: this.premix,
        })
        .then((response) => {
          this.requestStatus = false;
          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.clearFields();
          this.setActiveTab("list");
        })
        .catch((error) => {
          this.requestStatus = false;
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>

  
  <style scoped>
.custom-md-field {
  width: 190px;
}

#form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 90px;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 80%; */
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

/* Optional: Customize scrollbar appearance */
.custom-textarea::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.custom-textarea::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
}

.custom-textarea::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change the color on hover */
}

.struct-last {
  display: grid;
  grid-template-columns: 240px 240px;
  grid-column-gap: 5%;
}

.sec-3 {
  padding: 20px 0px;
}

.custom-textarea::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set the color of the scrollbar track */
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.test2 {
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.md-field {
  margin: 4px 0 24px;
  display: flex;
  position: relative;
  font-family: inherit;
}

.md-field.md-theme-demo-light.md-has-textarea:not(.md-autogrow):before {
  border-color: #448aff;
  border-color: #448aff;
}

.md-field.md-has-textarea:not(.md-autogrow):before {
  border-width: 2px;
}

.md-field.md-has-textarea:not(.md-autogrow):after,
.md-field.md-has-textarea:not(.md-autogrow):before {
  height: auto;
  pointer-events: none;
  top: 0;
  bottom: 0;
  transform: none;
  background: 0 0 !important;
  border: 1px solid transparent;
  border-radius: 3px;
}

.md-field.md-has-textarea:not(.md-autogrow) label {
  left: 30;
}

*,
:after,
:before {
  box-sizing: inherit;
}

.md-field:before {
  height: 2px;
  z-index: 2;
  opacity: 0;
  transform: scaleX(0.12);
}

.md-field .md-input,
.md-field .md-textarea {
  height: 36px;
  padding: 7px 0;
  font-size: 14px !important;
  -webkit-text-fill-color: #000000 !important;
}

.md-field.md-has-textarea:not(.md-autogrow).md-focused .md-textarea,
.md-field.md-has-textarea:not(.md-autogrow).md-has-value .md-textarea {
  padding-top: 0px;
}

textarea {
  display: block;
  /* margin-bottom: 20px; */
}
.textarea {
  display: block;
  margin-bottom: 20px;
  width: 100%; /* Take the full width */
  padding: 10px; /* Some padding for comfort */
  box-sizing: border-box; /* To ensure padding doesn't affect overall width */
  border: 1px solid #ccc; /* Basic border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Readable text size */
  resize: vertical; /* Allow vertical resizing, but not horizontal */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
}
.textarea:focus {
  border-color: #007bff; /* Highlight with a color when focused */
  outline: none; /* Remove the default outline */
}

.md-card-content {
  display: flex;
  flex-direction: column;
}

label {
  display: block;
  margin-bottom: 5px;
}

button[type="submit"],
button[type="button"].cancel-button {
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 30px;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.expinfo {
  display: grid;
  grid-template-columns: 45% 55%;
  grid-row-gap: 25px;
  grid-column-gap: 85px;
  /* border-bottom: solid black; */
}
</style>