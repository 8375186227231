<template>
    <div>
        <div class="sticky-top bg-white border-top border-bottom ml-3 mr-3">
                    <div class="ml-4 mr-4">
                        <div class="d-flex">
                            <div class="ml-4"></div><div class="ml-5"></div><div class="ml-5"></div>
                            <div v-if="!this.preview" class="col-md-1">
                                <md-button class="md-success" @click="previewToggle()">
                                    <md-icon>remove_red_eye</md-icon> Preview
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="ml-5">
                                <md-button class="md-info" @click="BackFn">
                                Back
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="pl-4">
                                <!-- <md-button class="md-success" @click="exportToPDF">
                                    <md-icon>download</md-icon> Download PDF
                                </md-button> -->
                                <md-button @click="exportToPDF"  type="button" class="md-success" :disabled="download"><md-icon>download</md-icon>{{download ? 'Downloading...':'Download PDF'}}
                                </md-button>
                            </div>
                        </div>
                    </div>
        </div>
 <div class="d-flex justify-content-center" id="theme">
    <div style="width:69%">
        <md-card>
            <md-card-content>
                <div ref="pdfContent" class="content-to-export mr-2 ml-2">
                <div class="certificate-container">
                <div class="header border border-dark">
                    <h1 class="m-0">INDOUS RESEARCH</h1>
                    <h5 class="m-0">A unit of INDOUS HOLDINGS</h5>
                </div>
                <!-- <hr style="border: 1px solid black; width: 100%; margin: 20px 0;"> -->

                <div class="border border-dark pl-3 pr-3 mt-5">
                <div class="d-flex">
                        <!-- <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> -->
                        <div class="pl-5"></div>
                        <div><h3 class="mt-1 pl-4"><u>BROILER NUTRITION TRIAL REPORT</u></h3></div>
                        <div class="mt-2 pl-5"><h5 class="mt-1 mb-0"><b>{{ this.trialNo }}</b></h5></div>
                                    <!-- <div class="pt-2 pl-5">
                                        <select v-if="!this.preview" required v-model="trialNo" id="country" md-dense>
                                            <option v-for="trial in trialNumbers" :key="trial.id" :value="trial.trial_no">
                                                        {{ trial.trial_no }}
                                            </option>
                                        </select>
                                        <input v-if="!this.preview" v-model="trialNumbers[0].trial_no" readonly style="padding:9px;font-size: medium;font-weight: 600;width:15%" />
                                    </div> -->
                </div>
                <div class="d-flex justify-content-end pr-3 mr-4">
                    <div v-if="!this.preview" style="width: 30%;">
                        <div class="d-flex align-items-center pr-0">
                            <span><b>Date:- </b></span>
                            <md-datepicker v-model="startDate" md-immediately style="margin-top:0px" />
                        </div>
                    </div>
                        <p v-if="this.preview" class="mt-0 mb-0 pr-4" style="font-size:17px;">Date:- {{ startDate }}</p>
                    </div>
                <div>
                    <p class="fs-5">To,</p>
                                <div class="ml-4">
                                    <input 
                                        type="text" 
                                        class="form-control rounded mb-2 font-color"
                                        v-model="name"
                                        placeholder="Enter Name Here"
                                        required
                                        style="width: 30%;"
                                        v-if="!this.preview"
                                    >
                                    <p v-if="this.preview" class="font-color" id="place">{{this.name}}</p>
                                    <!-- <md-field v-if="!this.preview">
                                                <label>Quantity</label>
                                                <md-input v-model="name" type="text"></md-input>
                                              </md-field> -->
                        <!-- <p class="fs-5">The Managing Partner</p> -->
                        <input 
                                        type="text" 
                                        class="form-control rounded mb-2 font-color"
                                        v-model="address1"
                                        placeholder="Enter Address"
                                        required
                                        style="width: 30%;"
                                        v-if="!this.preview"
                                    >
                        <p v-if="this.preview" class="font-color" id="place">{{this.address1}}</p>
                        <!-- <p class="fs-5">Bharath Agrovet Industries</p> -->
                        <input 
                                        type="text" 
                                        class="form-control rounded mb-2 font-color"
                                        v-model="address2"
                                       
                                        required
                                        style="width: 30%;"
                                        v-if="!this.preview"
                                    >
                        <p v-if="this.preview" class="font-color" id="place">{{this.address2}}</p>
                        <input 
                                        type="text" 
                                        class="form-control rounded mb-2 font-color"
                                        v-model="address3"
                                       
                                        required
                                        style="width: 30%;"
                                        v-if="!this.preview"
                                    >
                        <p v-if="this.preview" class="font-color" id="place">{{this.address3}}</p>
                    </div>
                    <p 
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 1 }" 
                        @focus="onEdit(1)" 
                        @blur="onBlur"
                     >Respected Sir,</p>
                    <p class="subject" 
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 2 }" 
                        @focus="onEdit(2)" 
                        @blur="onBlur"
                    >Sub:- Submitting the results for the experiment conducted on</p>
                    <p 
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 3 }" 
                        @focus="onEdit(3)" 
                        @blur="onBlur"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"A Comparative Study to evaluate the calcium and phosphorus requirement on the performance of commercial Broilers and its economics".</p>
            
                    <p 
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 4 }" 
                        @focus="onEdit(4)" 
                        @blur="onBlur"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With reference to the above mentioned subject we have conducted a Trial between to We are submitting the Final Report on the outcome of the experiment, Trial No {{ this.trialNo }}. 
                    This is for your kind information and reference.
                    </p>
            
                    <p
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 6 }" 
                        @focus="onEdit(6)" 
                        @blur="onBlur" 
                    >&nbsp;&nbsp;&nbsp;&nbsp;We are thankful to you for giving us this Opportunity and Sponsoring the Trial Expenses.</p>
            
                    <p
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 7 }" 
                        @focus="onEdit(7)" 
                        @blur="onBlur"
                    >Thanking You</p>
                    <br>
            
                    <p
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 8 }" 
                        @focus="onEdit(8)" 
                        @blur="onBlur"
                    
                    >Your's faithfully</p>
                    <br>
                    <br>
                    <p
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 9 }" 
                        @focus="onEdit(9)" 
                        @blur="onBlur"><strong>FOR INDOUS RESEARCH</strong></p>
                </div>
                </div>
            </div>
            <div class="border border-dark mt-5"></div>
            <div class="d-flex align-items-start w-100 px-3">
                                    <p 
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 10 }" 
                                        @focus="onEdit(10)" 
                                        @blur="onBlur"
                                        style="font-size:12px"
                                    >
                                        1st Floor, Annapoorneshwari Building, Mahaveera Circle Pumpwell, Mangalore -575002
                                    </p>
            </div>
        </div>
            </md-card-content>
        </md-card>
    </div>
    </div>
    <!-- <div class="d-flex justify-content-center">
            <div style="width:69%">
            <md-card>
                <md-card-content>
                        <div class="d-flex flex-row">
                            <div v-if="!this.preview" class="col-md-1">
                                <md-button class="md-success" @click="previewToggle()">
                                    <md-icon>remove_red_eye</md-icon> Preview
                                </md-button>

                                <md-button v-if="updateBtnVisible" @click="cancelPenUpdate()"  type="button" class="md-success" style="margin-left: 12px;" :disabled="requestStatus">{{ 'Cancel' }}</md-button>
                                 <md-datepicker v-model="startDate" md-immediately class="mt-0 pt0 mb-0 mt-0" style="width:20%"/>
                            </div>

                            <div v-if="this.preview" class="col-md-1">
                                <md-button class="md-info" @click="BackFn">
                                Back
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="col-md-1 ml-5">
                                <md-button class="md-success" @click="exportToPDF">
                                    <md-icon>download</md-icon> Download PDF
                                </md-button>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div> -->
    </div>
</template>
  
  <script>
//   import LoadingBar from "../../components/LoadingBar.vue";
 import jsPDF from 'jspdf';
 import html2canvas from 'html2canvas';
  export default {
//   components: {LoadingBar},

    data() {
      return {
        trialNo: "T10/10/22",
        currDate: "30-11-2022",
        name:'',
        preview:false,
        startDate:'',
        contentEdit:true,
        address1:'',
        address2:'',
        address3:'',
        currentEditing:null,
        download:false
      };
    },

    mounted(){
        this.getTrialNo();
        this.$material.locale.dateFormat = "dd-MM-yyyy";
        this.date_function();
    },

    computed: {
    startDateFormatted() {
      if (!this.startDate) return "";
      const [year, month, day] = this.startDate.split("-");
      return `${day}-${month}-${year}`;
    },
  },

    methods:{
        getTrialNoList() {
        axios
            .get(`/indous-research/experiment-information/get-trial-no-list`)
            .then((response) => {
            // this.trialNoList = response.data.trialNoList;
            this.trialNo = this.trialNoList[0]?.trial_no;
            // this.trialNoDrp = this.trialNoList[0]?.id;
            // this.expInfoId = this.trialNoList[0]?.id;
            this.startingDate = this.trialNoList[0]?.start_date;
            this.filteredChickCategory();
            this.fetchFeedFormulaList();
            });
        },
        onEdit(val){
            this.currentEditing=val;
        },

        onBlur(){
            this.currentEditing=null;
        },

        getTrialNo(){
            axios.get('/indous-research/experiment-information/get-trial-no-list').then(response=>{
            const temp = response.data.trialNoList[0].trial_no;
            this.trialNo = "T" + temp.slice(5);
            })
        },

        date_function(){
            // console.log("hii");
            const date = new Date();
            const formattedDate = date.toLocaleDateString('en-GB'); // dd/mm/yyyy format
            const finalDate = formattedDate.replace(/\//g, '-');
            // console.log("ddf",finalDate);
            this.startDate=finalDate;
            // console.log("df",this.startDate);
        },

        previewToggle(){
            this.preview = true;
            this.contentEdit = false;
        },

        exportToPDF() {
            this.download=true;
            const pdfContent = this.$refs.pdfContent;
            html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('Result_Submission.pdf');
                this.download=false;
            });
        },

        BackFn(){
            this.preview=false;
            this.contentEdit = true;

            // let [day, month, year] = this.currDate.split('-');

            // // Rearrange into 'dd-MM-yyyy' format
            // this.currDate = `${year}-${month}-${day}`;
            // console.log("bk",this.currDate);
        },

        // previewToggle(){
        //     this.preview=true;
        //     let [year, month, day] = this.currDate.split('-');

        //     // Rearrange into 'dd-MM-yyyy' format
        //     this.currDate = `${day}-${month}-${year}`;
        //     console.log("pr",this.currDate);
        // }
    }
  };
  </script>
  
  <style scoped>
  .certificate-container {
    font-family: Arial, sans-serif;
    text-align: left;
    width: 100%;
    padding: 20px 25px;
  }
  
  .header {
    text-align: center;
    /* margin-bottom: 20px; */
  }
  
  .header h2 {
    font-size: 24px;
    /* margin-bottom: 10px; */
  }
  
  .header h3 {
    font-size: 20px;
    font-weight: bold;
    /* margin-bottom: 20px; */
  }
  
  .details {
    display: flex;
    justify-content: flex-end;
    margin: 0px 10px 20px 0px;
    font-size: 17px;
  }
  
  .text-right {
    text-align: right;
  }
  
  .content {
    /* margin-top: 20px; */
  }
  
  /* .content p {
    margin: 10px 0;
  } */
  
  .subject {
    /* margin-top: 20px; */
  }
  </style>
  