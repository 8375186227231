<template>
<div>
    <md-toolbar md-elevation="0" class="md-transparent mb-4" style="background-color: lightgrey !important;">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title font-weight-normal text-uppercase">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <!-- <div class="md-autocomplete">
            <md-autocomplete
              class="search"
              v-model="selectedEmployee"
              :md-options="employees"
            >
              <label>Search...</label>
            </md-autocomplete>
          </div> -->
          <md-list>
            <!-- <md-list-item href="#/">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dashboard</p>
            </md-list-item> -->

            <!-- <md-list-item href="#/notifications" class="dropdown">
              <drop-down>
                <a slot="title" class="dropdown-toggle" data-toggle="dropdown">
                  <i class="material-icons">notifications</i>
                  <span class="notification">5</span>
                  <p class="hidden-lg hidden-md">Notifications</p>
                </a>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a href="#">Mike John responded to your email</a></li>
                  <li><a href="#">You have 5 new tasks</a></li>
                  <li><a href="#">You're now friend with Andrew</a></li>
                  <li><a href="#">Another Notification</a></li>
                  <li><a href="#">Another One</a></li>
                </ul>
              </drop-down>
            </md-list-item> -->

            <!-- <li class="md-list-item">
              <a
                href="javascript:void(0)"
                class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <div class="md-list-item-content">
                  <drop-down>
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">3</span>
                      <p class="hidden-lg hidden-md">Notifications</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Notification 1</a></li>
                      <li><a href="#">Notification 2</a></li>
                      <li><a href="#">Notification 3</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li> -->

            <!-- <md-list-item href="#/user">
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item> -->
            <!-- <drop-down>
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>person</md-icon>
                     
                      <p class="hidden-lg hidden-md">Profile</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right py-0">
                    
                      <li class="p-0"><a class="d-block w-100 m-0 py-1" href="javascript:void(0)"  @click="logout">Logout</a></li>
                    </ul>
                   
            </drop-down> -->
              <!-- <div class="my-auto"> {{name}}</div> -->
              <div class="btn-group">
                <div class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <md-icon class="text-secondary   mr-1">person</md-icon> <span class="text-secondary mb-0">{{user.name}}</span>
                  </div>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                    data-target="#resetPass">Reset password</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript:void(0)"  @click="logout">Logout</a>
                  </div>
                </div>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
   <reset-password :user="user" id="resetPass"></reset-password>
</div>

    
</template>

<script>
import ResetPassword from "../../components/ResetPassword.vue";
export default {
    components: { ResetPassword },
  data() {
    return {
      selectedEmployee: null,
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],
      
      user:""
    };
  },
  mounted(){
    this.user = this.$store.getters.getUser;
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
     logout(){
      axios.post('/logout').then((response) => {
         this.$store.commit('clearAuth')
         this.$store.commit('setSubMenus',null)
          this.$router.push(`/login`)
        }).catch((err) => {
          // console.log(err.response.data.error)
        });
    }
  },
};
</script>

<style lang="css"></style>
