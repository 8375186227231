<template>
    <div>
        <div class="sticky-top bg-white border-top border-bottom ml-3 mr-3">
                    <div class="ml-4 mr-4">
                        <div class="d-flex">
                            <div class="ml-4"></div><div class="ml-5"></div><div class="ml-5"></div>
                            <div v-if="!this.preview" class="col-md-1">
                                <md-button class="md-success" @click="previewToggle()">
                                    <md-icon>remove_red_eye</md-icon> Preview
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="ml-5">
                                <md-button class="md-info" @click="BackFn">
                                Back
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="pl-4">
                                <!-- <md-button class="md-success" @click="exportToPDF">
                                    <md-icon>download</md-icon> Download PDF
                                </md-button> -->
                                <md-button @click="exportToPDF"  type="button" class="md-success" :disabled="download"><md-icon>download</md-icon>{{download ? 'Downloading...':'Download PDF'}}
                                </md-button>
                            </div>
                        </div>
                    </div>
        </div>
        <div class="d-flex justify-content-center" id="theme">
            <div style="width:69%">
                <md-card>
                    <md-card-content>
                        <div ref="pdfContent" class="content-to-export">
                            <div class="p-3">
                            <div class="d-flex flex-column align-items-center p-0 border border-dark">
                                    <h1 class="m-0">INDOUS RESEARCH</h1>
                                    <h5 class="m-0">A unit of INDOUS HOLDINGS</h5>
                            </div>
                            <hr style="width: 100%; margin: 0px 0px;">
                            <div class="border-custom pl-3 pr-3 pt-4">
                                <div class="d-flex flex-column align-items-center pl-3 pr-3">
                                    <div class="d-flex justify-content-between w-100 ">
                                        <div class="pt-3">
                                            <h4><b>{{ this.trialNo }}</b></h4>
                                            <!-- <loading-bar v-if="load" class="ml-4"></loading-bar>
                                            <div v-else> -->

                                            <!-- <select v-if="!this.preview" required v-model="trialNo" id="country" style="padding:9px;font-size: medium;font-weight: 600;" md-dense>
                                                <option v-for="trial in trialNumbers" :key="trial.id" :value="trial.trial_no">
                                                            {{ trial.trial_no }}
                                                </option>
                                            </select> -->
                                            <!-- <input v-if="!this.preview" v-model="trialNumbers[0].trial_no" readonly style="padding:9px;font-size: medium;font-weight: 600;width:15%" /> -->

                                            <!-- </div> -->
                                                <!-- <p >{{ trialNo }}</p> -->
                                        </div>
                                        <div>
                                            <span class="text-decoration-underline"><h3><b><u>Certificate</u></b></h3></span>
                                        </div>
                                        <div>
                                            <div v-if="!this.preview" style="width: 140px;">
                                                <md-datepicker v-model="startDate" md-immediately style="margin-top:0px;font-weight:600"/>
                                            </div>
                                            <h4 v-if="this.preview" class="pt-3"><b>{{ startDate }}</b></h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center mt-4">
                                    <h4 class="text-decoration-underline"><b><u>This is to certify that,</u></b></h4>
                                    <p 
                                        id="cstmFont1" 
                                        :contenteditable="contentEdit" 
                                        :class="[ { 'editing': currentEditing === 1 }, 'lh-base' ]"
                                        @focus="onEdit(1)" 
                                        @blur="onBlur" 
                                    >
                                    <b>
                                    “ A COMPARATIVE STUDY TO EVALUATE THE CALCIUM AND <br>PHOSPHORUS REQUIREMENT ON THE PERFORMANCE OF<br> COMMERCIAL BROILERS AND ITS ECONOMICS “
                                    </b></p>
                                </div>
                
                                <div class="text-left my-4 pl-3 fs-2" id="cstmFont2">
                                    <p 
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 2 }" 
                                        @focus="onEdit(2)" 
                                        @blur="onBlur"
                                        >
                                        Sponsored by <b>Bharath Agrovet Industries</b>, Pumpwell, <br>
                                        Mangalore,</p><br>
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 3}" 
                                        @focus="onEdit(3)" 
                                        @blur="onBlur"
                                        >
                                        Was carried out at Research and Development Trial Farm at<br>
                                    <b>INDOUS RESEARCH</b>, Jyothigudde, Mangalore 574143</p>
                                </div>

                                <div class="pb-5">
                                </div>

                                <div class="pb-5">
                                </div>

                            <div class="d-flex align-items-end flex-column pr-5">
                                <div>
                                    <div class="text-left">
                                        <div v-if="!this.preview" class="d-flex align-items-left pb-1">
                                            <span class="mt-3"><b>Dr</b></span>
                                            <input 
                                                type="text" 
                                                class="form-control rounded mb-2 font-color ml-2" 
                                                v-model="name"
                                                required
                                                style="width: 90%;"
                                                v-if="!this.preview"
                                            >
                                        </div>
                                    <p v-if="this.preview" class="font-color pb-2" id="place"><b>Dr {{this.name}}</b></p>
                                    <!-- <p><b>Dr Arun Kumar Rai</b></p> -->
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 4 }" 
                                        @focus="onEdit(4)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                    ><b>Principal Investigator</b></p>
                                    <p class="pb-3"
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 5 }" 
                                        @focus="onEdit(5)" 
                                        @blur="onBlur"
                                    ><b>Indous Research</b></p>
                                    </div>
                                    <div class="text-left">
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 6 }" 
                                        @focus="onEdit(6)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                    ><b>Managing Partner</b></p>
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 7 }" 
                                        @focus="onEdit(7)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                    >Bharath Agrovet Industries</p>
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 8 }" 
                                        @focus="onEdit(8)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                    >Mahaveera Circle, Pumpwell,</p>
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 9 }" 
                                        @focus="onEdit(9)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                    >Mangalore 575002, Karnataka</p>
                                    </div>
                                </div>
                            </div>
                
                            <!-- <div class="d-flex justify-content-between w-100 px-3 mt-5">
                                <div class="text-left">
                                <p>Dr Arun Kumar Rai</p>
                                <p><b>Principal Investigator</b></p>
                                <p>Indous Research</p>
                                </div>
                                <div class="text-right">
                                <p>Managing Partner</p>
                                <p>Bharath Agrovet Industries</p>
                                <p>Mahaveera Circle, Pumpwell,</p>
                                <p>Mangalore 575002, Karnataka</p>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="d-flex flex-row">
                            <div v-if="!this.preview" class="col-md-1">
                                <md-button class="md-success" @click="previewToggle()">
                                    <md-icon>remove_red_eye</md-icon> Preview
                                </md-button>

                                <md-button v-if="updateBtnVisible" @click="cancelPenUpdate()"  type="button" class="md-success" style="margin-left: 12px;" :disabled="requestStatus">{{ 'Cancel' }}</md-button>
                            </div>

                            <div v-if="this.preview" class="col-md-1">
                                <md-button class="md-info" @click="BackFn">
                                Back
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="col-md-1 ml-5">
                                <md-button class="md-success" @click="exportToPDF">
                                    <md-icon>download</md-icon> Download PDF
                                </md-button>
                            </div>
                        </div> -->
                    </div>

                        <div class="border border-dark mt-5"></div>
                        <div class="d-flex align-items-start w-100 px-3">
                                    <p 
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 10 }" 
                                        @focus="onEdit(10)" 
                                        @blur="onBlur"
                                        style="font-size:12px"
                                    >
                                        1st Floor, Annapoorneshwari Building, Mahaveera Circle Pumpwell, Mangalore -575002
                                    </p>
                        </div>
                    </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
  </template>

<script>
// import LoadingBar from "../../components/LoadingBar.vue";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
export default{

// components: {LoadingBar},

    data(){
        return {
            trialNo:'',
            currDate:'',
            preview:false,
            startDate:'',
            name:'',
            contentEdit:true,
            isEditing: false,
            currentEditing: null,
            download:false
            // load:true
        }
    },

    created(){
        // this.date_function();
    },

    mounted(){
        this.getTrialNo();
        this.$material.locale.dateFormat = "dd-MM-yyyy";
        this.date_function();
    },

    watch: {
        startDate(newVal, oldVal){
        }
    },

    methods:{
        onEdit(val) {
            this.currentEditing = val; // Enable editing state when focused
        },

        onBlur() {
            this.currentEditing = null;  // Disable editing state when focus is lost
        },

        exportToPDF() {
            this.download=true;
            const pdfContent = this.$refs.pdfContent;
            html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('Certificate.pdf');
                this.download=false;
            });
        },

        BackFn(){
            this.preview=false;
            // let [day, month, year] = this.currDate.split('-');

            // // Rearrange into 'dd-MM-yyyy' format
            // this.currDate = `${year}-${month}-${day}`;
            // console.log("bk",this.currDate);
            this.contentEdit=true;
        },

        getTrialNo(){
            axios.get('/indous-research/experiment-information/get-trial-no-list').then(response=>{
            const temp = response.data.trialNoList[0].trial_no;
            this.trialNo = "T" + temp.slice(5);
            })
        },

        date_function(){
            // console.log("hii");
            const date = new Date();
            const formattedDate = date.toLocaleDateString('en-GB'); // dd/mm/yyyy format
            const finalDate = formattedDate.replace(/\//g, '-');
            // console.log("ddf",finalDate);
            this.startDate=finalDate;
            // console.log("df",this.startDate);
        },

        previewToggle(){
            this.preview=true;
            // let [year, month, day] = this.currDate.split('-');

            // // Rearrange into 'dd-MM-yyyy' format
            // this.currDate = `${day}-${month}-${year}`;
            // console.log("pr",this.currDate);
            this.contentEdit=false;
        }
        
    },

    watch: {
        
        // trialNumbers: {
        //     // immediate: true,
        //     // handler(newList) {
        //     //   if (newList.length > 0) {
        //     //     this.trialNo = newList[0].trial_no;
        //     //   }
        //     // }
        //   },

        trialNo(newVal, oldVal) {
            
        }
    }
}

</script>

<style>
.editing {
                border: 2px dashed #007bff; /* Dashed border to indicate editing */
                /* padding: 5px;               Add padding inside the border */
                border-radius: 5px;          /* Make corners rounded */
        }


.border-custom {
            border: 2px solid black;
            width: 100%;
            margin: 0;
        }

hr {
          border: none;
          height: 2px;
          background-color: 2px solid #0000;
          margin-top: 40px;
      }

#cstmFont1 {
    font-size: 21px;
}

#cstmFont2 {
    font-size: 21px;
}
</style>